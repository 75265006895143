import { useFormik } from "formik";
import React, { useState } from "react";
import * as yup from "yup";

import { useDispatch, useSelector } from "react-redux";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import CustomInput from "../../Components/Atoms/CustomInput";
import CustomButton from "../../Components/Atoms/CustomButton";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import * as Yup from "yup";
import { IconButton } from "@mui/material";
import {
  changepasswordRequest,
  changepasswordResponseClear,
} from "../../Redux/ChangePassword/ChangePasswordAction";

const UserChangePassword = ({ title, open, handleClose, id }) => {
  const { ChangepasswordResponse, ChangepasswordLoading } = useSelector(
    (state) => state.changePassword
  );

  const dispatch = useDispatch();
  const [passVisible, setPassVisible] = useState(false);
  const [confPassVisible, setConfPassVisible] = useState(false);

  const validationArray = Yup.object({
    old_password: Yup.string()
      .required("Required")
      .min(8, "Password must be at least 8 characters long")
      .matches(/^\S*$/, "Password cannot contain spaces"),
    new_password: Yup.string()
      .required("Required")

      .min(10, "Password must be at least 8 characters long")

      .matches(/^\s*\S[\s\S]*$/g, "Password cannot contain only blankspaces")
      .matches(/[a-z]/, "Must have atleast 1 small letter.")
      .matches(/[A-Z]/, "Must have atleast 1 capital letter.")
      .matches(
        /^(?=.*\d)(?=.*[!@#$%^&*])/,
        "Must have atleast 1 number & 1 special letter."
      )
      .matches(/^.{8,}$/, "Minimum of 8 characters"),
  });

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
    },
    validationSchema: validationArray,
    onSubmit: (values, { resetForm }) => {
      dispatch(changepasswordRequest({ id, ...values }));
    },
  });

  useEffect(() => {
    if (ChangepasswordResponse?.success && ChangepasswordResponse?.message) {
      toast.success(ChangepasswordResponse?.message);
      formik.resetForm();
      handleClose();
    } else if (ChangepasswordResponse?.response?.data) {
      toast.error(ChangepasswordResponse?.response?.data?.message);
    }

    return () => {
      dispatch(changepasswordResponseClear());
    };
    return;
  }, [ChangepasswordResponse]);

  return (
    <CustomDialog
      maxWidth="sm"
      title={title}
      open={open}
      handleClose={handleClose}
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex">
              <p className="mb-1">Old Password</p>
              <p className="erroStar">*</p>
            </div>
            <CustomInput
              name="old_password"
              placeholder="Old Password"
              value={formik.values.old_password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              formik={formik}
              type={passVisible ? "text" : "password"}
              endAdornment={
                <IconButton
                  className="material-symbols-rounded"
                  onClick={() => setPassVisible(!passVisible)}
                >
                  {passVisible ? "visibility_off" : "visibility"}
                </IconButton>
              }
            />
          </div>
          <div className="col-md-12">
            <div className="d-flex">
              <p className="mb-1">New Password</p>
              <p className="erroStar">*</p>
            </div>

            <CustomInput
              name="new_password"
              placeholder="New Password"
              value={formik.values.new_password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              formik={formik}
              type={confPassVisible ? "text" : "password"}
              endAdornment={
                <IconButton
                  className="material-symbols-rounded"
                  onClick={() => setConfPassVisible(!confPassVisible)}
                >
                  {confPassVisible ? "visibility_off" : "visibility"}
                </IconButton>
              }
            />
          </div>
        </div>
        <div className="btnActnBar">
          <CustomButton
            btnType="secondary"
            label="Cancel"
            type={"button"}
            btnClass="flex-fill"
            onClick={handleClose}
          />
          <CustomButton
            type="submit"
            btnClass="flex-fill"
            label={ChangepasswordLoading ? "Loading..." : "Submit"}
          />
        </div>
      </form>
    </CustomDialog>
  );
};

export default UserChangePassword;
