import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomDialog from "../../Components/Molecules/CustomDialog";

import DropdownMenu from "../../Components/Atoms/DropdownMenu";
import { Avatar, Button, CircularProgress, Drawer } from "@mui/material";
import { Edit, KeyboardArrowDown } from "@mui/icons-material";
import ReturnedForm from "./ReturnedForm";
import DisposedForm from "./DisposedForm";
import { useFormik } from "formik";
import { getSampleListRequest } from "../../Redux/Sample/SampleActions";
import { useDispatch, useSelector } from "react-redux";
import NoRecords from "../../Components/Atoms/NoRecord";
import moment from "moment";
import toast from "react-hot-toast";
import TablePagination from "../../Components/Molecules/Pagination";
import * as yup from "yup";
import SearchInput from "../../Components/Atoms/SearchInput";
import ExportData, {
  handleDownloadFile,
} from "../../Components/Molecules/ExportData";
import TableHeader from "../../Components/Atoms/TableHeader";
import CustomButton from "../../Components/Atoms/CustomButton";
import ReportIssuedForm from "./ReportIssuedForm";
import ReportIssuedFilter from "./Filters/ReportIssuedFilter";

const ReportIssued = ({ tabs, selectedTab, handleTabChange }) => {
  const { SampleListPageResponse, SamplePutLoading, SampleListPageLoading } =
    useSelector((state) => state.sample);

  const { getUserResponse } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [id, setId] = useState(null);
  const [filter, setFilter] = useState(false);
  const [reset, setReset] = useState(false);
  const handleStatusChange = (status, id) => {
    setSelectedStatus(status);
    setId(id);
  };

  const handleClose = () => {
    setSelectedStatus(null);
    setId(null);
  };
  const [page, setPage] = useState(1);
  const searchSample = {
    filters: {
      search: {
        value: "",
      },
      status: {
        value: "REPORTS_ISSUED",
      },
      product_category_ref: {
        value: [],
        type: "array",
      },
      tested_by_ref: {
        value: [],
        type: "array",
      },
      sample_condition_ref: {
        value: [],
        type: "array",
      },
      rpt_expiry_date: {
        value: [],
        type: "date_range",
      },
      received_date: {
        value: [],
        type: "date_range",
      },
      received_by_ref: {
        value: [],
        type: "array",
      },
    },
    limit: 10,
    page: page,
    sort: "",
  };
  const searchFormik = useFormik({
    initialValues: searchSample,

    onSubmit: (values) => {
      if (
        values.filters.rpt_expiry_date.value[0] &&
        !values.filters.rpt_expiry_date.value[1]
      ) {
        toast.error("Expiry to date is required");
        return;
      } else if (
        !values.filters.rpt_expiry_date.value[0] &&
        values.filters.rpt_expiry_date.value[1]
      ) {
        toast.error("Expiry from date is required");
        return;
      } else if (
        values.filters.rpt_expiry_date.value[0] >
        values.filters.rpt_expiry_date.value[1]
      ) {
        toast.error("Expiry to date should not less than from date");
        return;
      } else if (
        values.filters.received_date.value[0] &&
        !values.filters.received_date.value[1]
      ) {
        toast.error("Received to date is required");
        return;
      } else if (
        !values.filters.received_date.value[0] &&
        values.filters.received_date.value[1]
      ) {
        toast.error("Received from date is required");
        return;
      } else if (
        values.filters.received_date.value[0] >
        values.filters.received_date.value[1]
      ) {
        toast.error("Received to date should not less than from date");
        return;
      }
      setPage(1);
      setReset(true);
      return dispatch(getSampleListRequest(payload));
    },
  });

  const payload = {
    filters: {
      search: {
        value: searchFormik.values.filters.search?.value,
      },
      status: {
        value: "REPORTS_ISSUED",
      },
      product_category_ref: {
        value:
          searchFormik.values.product_category_ref?.value?.map(
            (el) => el?._id
          ) || [],
        type: "array",
      },

      sample_condition_ref: {
        value:
          searchFormik.values.sample_condition_ref?.value?.map(
            (el) => el?._id
          ) || [],
        type: "array",
      },

      tested_by_ref: {
        value:
          searchFormik.values.tested_by_ref?.value?.map((el) => el?._id) || [],
        type: "array",
      },
      received_by_ref: {
        value:
          searchFormik.values.received_by_ref?.value?.map((el) => el?._id) ||
          [],
        type: "array",
      },
      rpt_expiry_date: {
        value:
          searchFormik.values.filters.rpt_expiry_date?.value[0] &&
          searchFormik.values.filters.rpt_expiry_date?.value[1]
            ? [
                searchFormik.values.filters.rpt_expiry_date?.value[0],
                searchFormik.values.filters.rpt_expiry_date?.value[1],
              ]
            : [],
        type: "date_range",
      },
      received_date: {
        value:
          searchFormik.values.filters.received_date?.value[0] &&
          searchFormik.values.filters.received_date?.value[1]
            ? [
                searchFormik.values.filters.received_date?.value[0],
                searchFormik.values.filters.received_date?.value[1],
              ]
            : [],
        type: "date_range",
      },
    },
    limit: 10,
    page: 1,
    sort: "",
  };

  useEffect(() => {
    dispatch(getSampleListRequest(payload));
  }, [SamplePutLoading, reset]);

  const handleFilter = () => {
    searchFormik.resetForm();
    setReset(false);
  };

  const headers = [
    "received_date",
    "applicant_name",
    "product_category_ref",
    "model_no",
    "no_of_samples_received",
    "standard",
    "allot_srf_id",
    "rpt_testreport_no",
    "rpt_issue_date",
    "tested_by_ref",
    "rpt_expiry_date",
    "status",
    "sub_status",
  ];
  const values = {
    filters: searchFormik.values.filters,
  };

  const handleExportButtonClick = () => {
    const date = moment(new Date()).format("DD_MM_YYYY");

    handleDownloadFile({
      url: "sample/report/get_report",
      filename: `reports_issued_${date}.xlsx`,
      payload: { headers, ...values },
      method: "POST",
    });
  };
  const reports = ["RETURNED", "DISPOSED"];

  const [report, setReport] = useState(false);
  const [reportEditData, setReportEditData] = useState(null);

  const handleReportEditOpen = (data) => {
    setReport(true);
    setReportEditData(data);
    setId(data?._id);
  };
  const handleReportEditClose = () => {
    setReport(false);
    setReportEditData(null);
    setId(null);
  };

  const getStatusText = (status) => {
    switch (status) {
      case "NILL":
        return "N/A";
      case "RETURNED":
        return "Returned";
      case "DISPOSED":
        return "Disposed";
      default:
        return "";
    }
  };

  const getClassName = (status) => {
    return status === "RETURNED" ? "text-primary" : "text-danger";
  };

  const openFilter = () => {
    setFilter(true);
  };
  const closeFilter = () => {
    setFilter(false);
  };
  return (
    <>
      <Drawer anchor="right" open={filter} onClose={closeFilter}>
        <ReportIssuedFilter
          searchFormik={searchFormik}
          handleFilterClose={closeFilter}
          open={filter}
          handleFilter={handleFilter}
        />
      </Drawer>
      <CustomDialog
        maxWidth="sm"
        title={reportEditData ? "Edit Report" : `Update Report `}
        handleClose={() => {
          handleReportEditClose();
        }}
        open={report}
      >
        <ReportIssuedForm
          handleClose={handleReportEditClose}
          sampleData={reportEditData}
          id={id}
          type="popup_edit"
        />
      </CustomDialog>
      <CustomDialog
        maxWidth="sm"
        title={
          selectedStatus === "returned" ? "Returned Info" : "Disposed Info"
        }
        handleClose={handleClose}
        open={selectedStatus !== null}
      >
        {selectedStatus === "returned" ? (
          <ReturnedForm id={id} handleClose={handleClose} />
        ) : (
          <DisposedForm id={id} handleClose={handleClose} />
        )}
      </CustomDialog>

      <TableHeader
        tabs={tabs}
        role={getUserResponse?.data?.role}
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
        searchFormik={searchFormik}
        showTab={true}
      >
        <SearchInput
          value={searchFormik.values.filters.search.value}
          name={"filters.search.value"}
          onChange={searchFormik.handleChange}
          handleSubmit={searchFormik.handleSubmit}
          searchFormik={searchFormik}
          searchSample={searchSample}
        />
        <CustomButton
          onClick={handleExportButtonClick}
          label={"Export"}
          size="small"
        />
        <CustomButton onClick={openFilter} label={"Filter"} size="small" />
      </TableHeader>
      {SampleListPageLoading && !SampleListPageLoading?.data?.length > 0 ? (
        <div className="d-flex align-items-center justify-content-center">
          <CircularProgress size={25} />
        </div>
      ) : (
        <TablePagination
          showFilter={true}
          showSort={true}
          setPage={setPage}
          page={page}
          initialValues={searchSample}
          formik={searchFormik}
          action={(values) => dispatch(getSampleListRequest(values))}
          result={SampleListPageResponse}
          count={SampleListPageResponse?.data?.total_count}
        >
          <div className="tblWrpr">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Sample&nbsp;Receipt</th>
                    <th>Applicant&nbsp;Name</th>
                    <th>Category</th>
                    <th>Model&nbsp;No.</th>
                    <th>No.&nbsp;Samples&nbsp;Rcvd</th>
                    <th>Standard</th>
                    <th>SRF&nbsp;ID</th>
                    <th>Test&nbsp;Report&nbsp;No</th>
                    <th>Issued&nbsp;Date</th>
                    <th>Tested&nbsp;By</th>
                    <th>Expiry&nbsp;Date</th>
                    <th>Sub&nbsp;Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {SampleListPageResponse?.data?.length > 0 ? (
                    SampleListPageResponse?.data?.map((data, index) => {
                      return (
                        <tr key={data._id}>
                          <td>
                            {searchFormik?.values?.limit * (page - 1) +
                              index +
                              1}
                          </td>
                          <td>
                            {moment(data?.received_date).format("DD/MM/YYYY")}
                          </td>
                          <td>{data?.applicant_name}</td>
                          <td>{data?.product_category_ref?.name}</td>
                          <td>{data?.model_no}</td>
                          <td>{data?.no_of_samples_received}</td>
                          <td>{data.standard}</td>
                          <td>{data.allot_srf_id}</td>
                          <td>{data.rpt_testreport_no}</td>
                          <td>
                            {/* {moment(data?.rpt_issue_date).format("DD/MM/YYYY")} */}
                            {data?.rpt_issue_date
                              ? moment(data?.rpt_issue_date).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </td>
                          <td>{data.tested_by_ref?.first_name}</td>
                          <td>
                            {/* {moment(data?.rpt_expiry_date).format("DD/MM/YYYY")} */}
                            {data?.rpt_expiry_date
                              ? moment(data?.rpt_expiry_date).format(
                                  "DD/MM/YYYY"
                                )
                              : "-"}
                          </td>
                          <td>
                            {" "}
                            <span
                              className={
                                data?.sub_status === "RETURNED"
                                  ? "text-primary"
                                  : "text-danger"
                              }
                            >
                              <span className={getClassName(data?.sub_status)}>
                                {getStatusText(data?.sub_status)}
                              </span>
                            </span>
                          </td>
                          <td className="">
                            <div className="d-flex gap-3">
                              <div className="view">
                                <button
                                  onClick={() => handleReportEditOpen(data)}
                                >
                                  <Edit />
                                </button>
                              </div>
                              <DropdownMenu
                                menuList={[
                                  {
                                    label: "Returned",
                                    onClick: () =>
                                      handleStatusChange("returned", data._id),
                                  },
                                  {
                                    label: "Disposed",
                                    onClick: () =>
                                      handleStatusChange("disposed", data._id),
                                  },
                                ]}
                              >
                                <Button variant="outlined" color="secondary">
                                  <span
                                    className="text-start text-capitalize"
                                    style={{ width: "120px" }}
                                  >
                                    Updated Status
                                  </span>
                                  <KeyboardArrowDown className="ms-2" />
                                </Button>
                              </DropdownMenu>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <NoRecords />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </TablePagination>
      )}
    </>
  );
};

export default ReportIssued;
