import UserTypes from "./GetUserActionTypes";

export const getUserRequest = (payload) => ({
  type: UserTypes.GET_USER_REQUEST,
});

export const getUserResponse = (payload) => ({
  type: UserTypes.GET_USER_RESPONSE,
  payload: payload,
});

export const getUserResponseClear = () => ({
  type: UserTypes.GET_USER_RESPONSE_CLEAR,
});

export const setSearchInput = (payload) => ({
  type: UserTypes.SET_HEADER_SEARCH_INPUT,
  payload: payload,
});

export const getSearchInput = () => ({
  type: UserTypes.GET_HEADER_SEARCH_INPUT,
});

export const getUserByIdRequest = (payload) => ({
  type: UserTypes.GET_USER_BY_ID_REQUEST,
  payload: payload,
});

export const getUserByIdResponse = (payload) => ({
  type: UserTypes.GET_USER_BY_ID_RESPONSE,
  payload: payload,
});

export const getUserByIdResponseClear = () => ({
  type: UserTypes.GET_USER_BY_ID_RESPONSE_CLEAR,
});

export const UnautherizedResponse = (payload) => ({
  type: UserTypes.UNAUTHERIZED_RESPONSE,
  payload: payload,
});

export const UnautherizedResponseClear = () => ({
  type: UserTypes.UNAUTHERIZED_RESPONSE_CLEAR,
});
