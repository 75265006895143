import { all } from "redux-saga/effects";
import { LoginWatcherSaga } from "./LoginPage/LoginSaga";
import { GetUserWatcherSaga } from "./GetUser/GetUserSaga";
import { ForgotAndResetPasswordWatcherSaga } from "./ForgotAndResetPassword/ForgotAndResetPasswordSaga";
import { EmployeeListWatcherSaga } from "./Employees/EmployeeSaga";
import { ConditionListWatcherSaga } from "./Settings/Condition/ConditonSaga";
import { categoryListWatcherSaga } from "./Settings/Category/CategorySaga";
import { sampleListWatcherSaga } from "./Sample/SampleSaga";
import { changepasswordWatcherSaga } from "./ChangePassword/ChangePasswordsaga";
import { CountryListWatcherSaga } from "./CountryState/Country/CountrySaga";
import { StateListWatcherSaga } from "./CountryState/State/StateSaga";

export default function* RootSaga() {
  yield all([
    LoginWatcherSaga(),
    GetUserWatcherSaga(),
    ForgotAndResetPasswordWatcherSaga(),
    EmployeeListWatcherSaga(),
    ConditionListWatcherSaga(),
    categoryListWatcherSaga(),
    sampleListWatcherSaga(),
    changepasswordWatcherSaga(),
    CountryListWatcherSaga(),
    StateListWatcherSaga(),
  ]);
}
