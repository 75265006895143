import React from "react";
import "./App.css";
import Router from "./Router";
import { Toaster } from "react-hot-toast";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0e479e",
    },
    secondary: {
      main: "#363636",
    },
  },
  typography: {
    fontFamily: "var(--primFont)",
  },
});

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Toaster position="top-right" reverseOrder={false} />
        <Router />
      </ThemeProvider>
    </div>
  );
}

export default App;
