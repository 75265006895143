import ConditionListTypes from "./ConditonActionTypes";

const initialState = {
  ConditionListPageLoading: false,
  ConditionListPageResponse: null,
  ConditionPostLoading: false,
  ConditionPostResponse: null,
  ConditionPutLoading: false,
  ConditionPutResponse: null,
  ConditionGetByIdLoading: false,
  ConditionGetByIdResponse: null,
};

const ConditionListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ConditionListTypes.GET_CONDITION_LIST_REQUEST:
      return {
        ...state,
        ConditionListPageLoading: true,
      };

    case ConditionListTypes.GET_CONDITION_LIST_RESPONSE:
      return {
        ...state,
        ConditionListPageLoading: false,
        ConditionListPageResponse: action.payload.data,
      };

    case ConditionListTypes.POST_CONDITION_REQUEST:
      return {
        ...state,
        ConditionPostLoading: true,
      };

    case ConditionListTypes.POST_CONDITION_RESPONSE:
      return {
        ...state,
        ConditionPostLoading: false,
        ConditionPostResponse: action.payload,
      };

    case ConditionListTypes.POST_CONDITION_RESPONSE_CLEAR:
      return {
        ...state,
        ConditionPostResponse: null,
      };

    case ConditionListTypes.GET_CONDITION_BYID_REQUEST:
      return {
        ...state,
        ConditionGetByIdLoading: true,
      };

    case ConditionListTypes.GET_CONDITION_BYID_RESPONSE:
      return {
        ...state,
        ConditionGetByIdLoading: false,
        ConditionGetByIdResponse: action.payload,
      };

    case ConditionListTypes.GET_CONDITION_BYID_RESCLEAR:
      return {
        ...state,
        ConditionGetByIdResponse: null,
      };

    case ConditionListTypes.PUT_CONDITION_REQUEST:
      return {
        ...state,
        ConditionPutLoading: true,
      };

    case ConditionListTypes.PUT_CONDITION_RESPONSE:
      return {
        ...state,
        ConditionPutLoading: false,
        ConditionPutResponse: action.payload,
      };

    case ConditionListTypes.PUT_CONDITION_RESPONSE_CLEAR:
      return {
        ...state,
        ConditionPutResponse: null,
      };

    default:
      return state;
  }
};

export default ConditionListReducer;
