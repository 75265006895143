import React from "react";
import "./index.css";

const EmployeeView = ({ employeeData }) => {
  if (!employeeData) {
    return null;
  }

  return (
    <>
      <div className="row">
        <div className="col-md-6 details_list">
          <p className="view_head">First Name</p>
          <p>:</p>
          <p className="view_values">{employeeData?.first_name}</p>
        </div>
        <div className="col-md-6 details_list">
          <h3 className="view_head">Last Name</h3>
          <p>:</p>
          <h3 className="view_values">{employeeData?.last_name}</h3>
        </div>
        <div className="col-md-6 details_list">
          <h3 className="view_head">Email</h3>
          <p>:</p>
          <h3 className="view_values">{employeeData?.email}</h3>
        </div>
        <div className="col-md-6 details_list">
          <h3 className="view_head">Phone Number</h3>
          <p>:</p>
          <h3 className="view_values">{employeeData?.phone_number}</h3>
        </div>{" "}
        <div className="col-md-6 details_list">
          <h3 className="view_head">Address</h3>
          <p>:</p>
          <h3 className="view_values">
            {employeeData?.address_detail?.address}
          </h3>
        </div>{" "}
        <div className="col-md-6 details_list">
          <h3 className="view_head">Country</h3>
          <p>:</p>
          <h3 className="view_values">
            {employeeData?.address_detail?.country}
          </h3>
        </div>
        <div className="col-md-6 details_list">
          <h3 className="view_head">State</h3>
          <p>:</p>
          <h3 className="view_values">{employeeData?.address_detail?.state}</h3>
        </div>
        <div className="col-md-6 details_list">
          <h3 className="view_head">Zip Code</h3>
          <p>:</p>
          <h3 className="view_values">
            {employeeData?.address_detail?.zipcode}
          </h3>
        </div>
        <div className="col-md-6 details_list">
          <h3 className="view_head">Role</h3>
          <p>:</p>
          <h3 className="view_values">{employeeData?.role?.toLowerCase()}</h3>
        </div>
      </div>
    </>
  );
};

export default EmployeeView;
