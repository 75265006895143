import React, { useEffect, useState } from "react";
import "./index.css";
import SearchInput from "../../Components/Atoms/SearchInput";
import { Link } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
} from "@mui/material";
import { ExpandMore, KeyboardArrowDown } from "@mui/icons-material";
import FullScreenDialog from "../../Components/Molecules/FullScreenDialog";
import SearchFilterData from "./SearchFilterData";
import DropdownMenu from "../../Components/Atoms/DropdownMenu";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getSampleListRequest,
  getSampleStatusRequest,
  putSampleResponseClear,
} from "../../Redux/Sample/SampleActions";
import { getEmployeeListRequest } from "../../Redux/Employees/EmployeesActions";
import { getCategoryListRequest } from "../../Redux/Settings/Category/CategoryActions";
import { getConditionListRequest } from "../../Redux/Settings/Condition/ConditionActions";
import toast from "react-hot-toast";
import NoRecords from "../../Components/Atoms/NoRecord";
import moment from "moment";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import AllotForm from "../Samples/AllotForm";
import SampleForm from "../Samples/SampleForm";
import CountUp from "react-countup";
import { ROLE, role } from "../../Constant";
import { getPurposeOfTestingDisplayName } from "../../Utils/getters";

const Dashboard = () => {
  const [open, setOpen] = useState(false);
  const [sampleData, setSampleData] = useState(false);
  const [type, setType] = useState("");
  const [id, setId] = useState(null);
  const [openAllot, setOpenAllot] = useState(false);
  const {
    SampleStatusResponse,

    SamplePostLoading,
    SampleListPageResponse,
    SamplePutLoading,
    SampleListPageLoading,
    SamplePutResponse,
  } = useSelector((state) => state.sample);

  const { getUserResponse } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const handleOpen = (type) => {
    setOpen(true);
    setType(type);
  };

  const handleOpenAllot = (id) => {
    setOpenAllot(true);
    setId(id);
  };
  const handleOpenAllotClose = () => {
    setOpenAllot(false);
    setId(null);
  };

  const searchSchema = {
    filters: {
      search: {
        value: "",
      },
      status: {
        value: "true",
      },
    },
  };

  useEffect(() => {
    dispatch(
      getEmployeeListRequest({ ...searchSchema, sort: "first_name:asc" })
    );
    dispatch(getCategoryListRequest({ ...searchSchema, sort: "name:asc" }));
    dispatch(getConditionListRequest({ ...searchSchema, sort: "name:asc" }));
    dispatch(getSampleStatusRequest());
  }, []);

  useEffect(() => {
    dispatch(getSampleStatusRequest());
  }, [SamplePostLoading, SamplePutLoading]);
  const [page, setPage] = useState(1);
  const searchSample = {
    filters: {
      search: {
        value: "",
      },
      sub_status: {
        value: "",
      },
      status: {
        value: "",
      },
    },
    limit: 10,
    page: page,
    sort: "",
  };

  const searchFormik = useFormik({
    initialValues: searchSample,
    onSubmit: (values) => {
      return dispatch(getSampleListRequest(values));
    },
  });
  const handleStatusChange = (status) => {
    searchFormik.setFieldValue("filters.sub_status.value", status);
    searchFormik.setFieldValue("filters.status.value", "ALLOTTED");

    setOpen(true);
  };

  const handleClose = () => {
    searchFormik.setFieldValue("filters.sub_status.value", "");
    searchFormik.setFieldValue("filters.status.value", "");
    searchFormik.setFieldValue("filters.search.value", "");
    setOpen(false);
    setType("");
  };

  useEffect(() => {
    dispatch(getSampleListRequest(searchFormik.values));
  }, [
    searchFormik.values.filters.sub_status,
    SamplePutLoading,
    SamplePostLoading,
  ]);

  useEffect(() => {
    if (SamplePutResponse?.success && SamplePutResponse?.message) {
      toast.success(SamplePutResponse?.message);
      setSampleData(null);
    } else if (SamplePutResponse?.response?.data) {
      toast.error(SamplePutResponse?.response?.data?.message);
    }

    return () => {
      dispatch(putSampleResponseClear());
    };
  }, [SamplePutResponse]);

  return (
    <>
      <CustomDialog
        maxWidth="md"
        title="Allot For Testing"
        handleClose={() => {
          handleOpenAllotClose();
        }}
        open={openAllot}
      >
        <AllotForm handleClose={handleOpenAllotClose} id={id} />
      </CustomDialog>

      <FullScreenDialog
        open={open}
        handleClose={handleClose}
        title={`Result: Found ${
          SampleListPageResponse?.total_count
        } results for ${
          searchFormik.values.filters.search.value
            ? searchFormik.values.filters.search.value
            : searchFormik.values.filters.sub_status.value
            ? searchFormik.values.filters.sub_status.value
            : searchFormik.values.filters.search.value &&
              searchFormik.values.filters.sub_status.value
            ? `${searchFormik.values.filters.search.value} , ${searchFormik.values.filters.sub_status.value}`
            : "Sample List"
        }`}
      >
        <SearchFilterData
          SampleListPageResponse={SampleListPageResponse}
          searchFormik={searchFormik}
          searchSample={searchSample}
          setPage={setPage}
          page={page}
          SampleListPageLoading={SampleListPageLoading}
          type={type}
          // formik={formik}
        />
      </FullScreenDialog>
      <div className="dashboard_container">
        <div className="dashboard_grid_view">
          <div className="dshStCard">
            <div className="rtTxtIfo">
              <label>Search Samples</label>

              <SearchInput
                name="filters.search.value"
                value={searchFormik.values.filters.search.value}
                onChange={searchFormik.handleChange}
                handleSubmit={searchFormik.handleSubmit}
                onSubmit={() => handleOpen()}
                searchFormik={searchFormik}
                searchSample={searchSample}
              />
            </div>
          </div>
          <div className="dshStCard">
            <div className="rtTxtIfo">
              <label>Filter </label>
              <div className="bg-clr">
                <DropdownMenu
                  inputWidth="210px"
                  menuList={[
                    {
                      label: "In-Testing",
                      onClick: () => handleStatusChange("IN_TESTING"),
                    },

                    {
                      label: "Cancelled",
                      onClick: () => handleStatusChange("CANCELLED"),
                    },
                  ]}
                >
                  <Button variant="outlined" color="secondary">
                    <span
                      className="text-start text-capitalize"
                      style={{ width: "220px" }}
                    >
                      Updated Status
                    </span>
                    <KeyboardArrowDown className="ms-2" />
                  </Button>
                </DropdownMenu>
              </div>
            </div>
          </div>
          <div className="dshStCard">
            <div className="rtTxtIfo">
              <label>Completed </label>
              <h2>
                <CountUp
                  delay={1}
                  end={SampleStatusResponse?.data?.completed}
                />
              </h2>
            </div>
          </div>
          <div className="dshStCard">
            <div className="rtTxtIfo">
              <label>Pending</label>
              <h2>
                <CountUp delay={1} end={SampleStatusResponse?.data?.pending} />
              </h2>
            </div>
          </div>
        </div>

        {getUserResponse?.data?.role === ROLE.ADMIN && (
          <div className="accordian_container">
            <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <h6>Add Sample</h6>
              </AccordionSummary>
              <AccordionDetails className="">
                <SampleForm sampleData={sampleData} />
              </AccordionDetails>
            </Accordion>
          </div>
        )}

        <div className="table_title">
          <p>Samples List</p>
          <Link onClick={() => handleOpen("view")}>View All</Link>
        </div>

        {SampleListPageLoading && !SampleListPageLoading?.data?.length > 0 ? (
          <div className="d-flex align-items-center justify-content-center">
            <CircularProgress size={25} />
          </div>
        ) : (
          <div className="tblWrpr">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Rcvd.&nbsp;Date</th>
                    <th>Applicant&nbsp;Name</th>
                    <th>Category</th>
                    <th>Model&nbsp;No.</th>
                    <th>No.&nbsp;Samples&nbsp;Rcvd</th>
                    <th>Condition</th>
                    <th>Rcvd.&nbsp;By</th>
                    <th>Purpose&nbsp;of&nbsp;Testing</th>

                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {SampleListPageResponse?.data?.length > 0 ? (
                    SampleListPageResponse?.data
                      ?.slice(0, 4)
                      .map((data, index) => {
                        return (
                          <tr key={data._id}>
                            <td>
                              {" "}
                              {searchFormik?.values?.limit * (page - 1) +
                                index +
                                1}
                            </td>
                            <td>
                              {moment(data?.received_date).format("DD/MM/YYYY")}
                            </td>
                            <td>{data.applicant_name}</td>
                            <td>{data.product_category_ref?.name}</td>
                            <td>{data.model_no}</td>
                            <td>{data.no_of_samples_received}</td>
                            <td className="td">
                              {data.sample_condition_ref?.name}
                            </td>
                            <td>{data?.received_by_ref?.first_name}</td>
                            <td>
                              {getPurposeOfTestingDisplayName(
                                data?.purpose_of_testing
                              )}
                              {/* {data?.purpose_of_testing === "BIS_MODIFICATION"
                                ? "BIS Modification"
                                : data?.purpose_of_testing === "BIS_FULLTESTING"
                                ? "BIS Fulltesting"
                                : data?.purpose_of_testing ===
                                  "NABL_PERFORMANCE"
                                ? "NABL Perfomance"
                                : data?.purpose_of_testing === "R_AND_D"
                                ? "R&D"
                                : data?.purpose_of_testing === "OTHERS"
                                ? "Others"
                                : ""} */}
                            </td>

                            <td>
                              <div className="view">
                                {(data.status === "UN_ALLOTTED" && (
                                  <Link
                                    onClick={() => handleOpenAllot(data._id)}
                                  >
                                    Click&nbsp;here&nbsp;to&nbsp;allot&nbsp;for&nbsp;testing
                                  </Link>
                                )) ||
                                  "_"}
                              </div>
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <NoRecords />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;
