import { PURPOSE_OF_TESTING_DATA } from "../Constant";

export const getPurposeOfTestingDisplayName = (val) => {
  const obj = PURPOSE_OF_TESTING_DATA.find((e) => e.value === val);
  if (obj) {
    const { name } = obj;
    return name;
  }
  return "";
};
