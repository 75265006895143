import { combineReducers } from "@reduxjs/toolkit";
import loginReducer from "./LoginPage/LoginReducer";
import GetUserReducer from "./GetUser/GetUserReducer";
import ForgotAndResetPasswordReducer from "./ForgotAndResetPassword/ForgotAndResetPasswordReducer";
import EmployeeListReducer from "./Employees/EmployeeReducer";
import ConditionListReducer from "./Settings/Condition/ConditonReducer";
import CategoryListReducer from "./Settings/Category/CategoryReducer";
import SampleListReducer from "./Sample/SampleReducer";
import ChangePasswordReducer from "./ChangePassword/ChangePasswordReducer";
import CountryListReducer from "./CountryState/Country/CountryReducer";
import StateListReducer from "./CountryState/State/StateReducer";

const rootReducer = combineReducers({
  loginReducer: loginReducer,
  user: GetUserReducer,
  forgotAndResetPasswordReducer: ForgotAndResetPasswordReducer,
  employee: EmployeeListReducer,
  condition: ConditionListReducer,
  category: CategoryListReducer,
  sample: SampleListReducer,
  changePassword: ChangePasswordReducer,
  country: CountryListReducer,
  state: StateListReducer,
});

export default rootReducer;
