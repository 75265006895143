export default {
  GET_CATEGORY_LIST_REQUEST: "GET_CATEGORY_LIST_REQUEST",
  GET_CATEGORY_LIST_RESPONSE: "GET_CATEGORY_LIST_RESPONSE",

  GET_CATEGORY_BYID_REQUEST: "GET_CATEGORY_BYID_REQUEST",
  GET_CATEGORY_BYID_RESPONSE: "GET_CATEGORY_BYID_RESPONSE",
  GET_CATEGORY_BYID_RESCLEAR: "GET_CATEGORY_BYID_RESCLEAR",

  POST_CATEGORY_REQUEST: "POST_CATEGORY_REQUEST",
  POST_CATEGORY_RESPONSE: "POST_CATEGORY_RESPONSE",
  POST_CATEGORY_RESPONSE_CLEAR: "POST_CATEGORY_RESPONSE_CLEAR",

  PUT_CATEGORY_REQUEST: "PUT_CATEGORY_REQUEST",
  PUT_CATEGORY_RESPONSE: "PUT_CATEGORY_RESPONSE",
  PUT_CATEGORY_RESPONSE_CLEAR: "PUT_CATEGORY_RESPONSE_CLEAR",
};
