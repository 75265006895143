import PasswordTypes from "./ChangePasswordActionType";

export const changepasswordRequest = (payload, id) => ({
  type: PasswordTypes.CHANGE_PASSWORD_REQUEST,
  payload: payload,
  id: id,
});

export const changepasswordResponse = (payload) => ({
  type: PasswordTypes.CHANGE_PASSWORD_RESPONSE,
  payload: payload,
});

export const changepasswordResponseClear = () => ({
  type: PasswordTypes.CHANGE_PASSWORD_CLEAR,
});
