import React from "react";
import { Button } from "@mui/material";
import "./index.css";

const CustomButton = (props) => {
  const { btnType, btnClass } = props;
  const getButtonClass = () => {
    switch (btnType) {
      case "terinary":
        return "terinary";
      case "secondary":
        return "secButton";
      default:
        return "primButton";
    }
  };
  return (
    <div className={`btnWrpr ${btnClass || ""} ${getButtonClass()}`}>
      <Button
        className={`${props.size} ${props.className}`}
        variant={props.btnType === "secondary" ? "outlined" : "contained"}
        onClick={props.onClick}
        type={props.type}
        size={props.size}
        disabled={props.disabled}
        color={props.color}
      >
        {props.label}
      </Button>
    </div>
  );
};

export default CustomButton;
