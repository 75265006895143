import React, { useEffect, useState } from "react";
import CustomInput from "../../Components/Atoms/CustomInput";
import { useNavigate, useSearchParams } from "react-router-dom";
import CustomButton from "../../Components/Atoms/CustomButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import AuthLayout from "../../Components/Layout/AuthLayout";
import {
  postResetPasswordRequest,
  postResetPasswordResponseClear,
} from "../../Redux/ForgotAndResetPassword/ForgotAndResetPasswordActions";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [passVisible, setPassVisible] = useState(false);
  const [confPassVisible, setConfPassVisible] = useState(false);
  const [searchParams] = useSearchParams();
  const { PostResetPasswordLoading, PostResetPasswordResponse } = useSelector(
    (state) => state.forgotAndResetPasswordReducer
  );

  const validationArray = Yup.object().shape({
    password: Yup.string()
      .min(8, "password must be 8 letters")
      .required("Required"),
    confirm_password: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
    },
    validationSchema: validationArray,
    onSubmit: async (values) => {
      dispatch(
        postResetPasswordRequest({
          token: searchParams.get("token"),
          id: searchParams.get("id"),
          values: values,
        })
      );
    },
  });

  useEffect(() => {
    if (
      PostResetPasswordResponse?.success &&
      PostResetPasswordResponse?.message
    ) {
      toast.success(PostResetPasswordResponse?.message);
      navigate("/login");
    } else if (PostResetPasswordResponse?.response?.data) {
      toast.error(PostResetPasswordResponse?.response?.data?.message);
    }

    return () => {
      dispatch(postResetPasswordResponseClear());
    };
    return;
  }, [PostResetPasswordResponse]);

  return (
    <AuthLayout showButton={true}>
      <div className="lgnFormContainer">
        <form onSubmit={formik.handleSubmit}>
          <div className="frmHdr text-center">
            <h5>Reset Password</h5>
            <p className="mt-3">Please reset your password before continue</p>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex">
                <p className="mb-1">Password</p>
                <p className="erroStar">*</p>
              </div>
              <CustomInput
                name="password"
                autoComplete="off"
                placeholder="Enter password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
                formik={formik}
                type={passVisible ? "text" : "password"}
                endAdornment={
                  <IconButton
                    className="material-symbols-rounded"
                    onClick={() => setPassVisible(!passVisible)}
                  >
                    {passVisible ? "visibility_off" : "visibility"}
                  </IconButton>
                }
              />
            </div>

            <div className="col-md-12">
              <div className="d-flex">
                <p className="mb-1">Confirm Password</p>
                <p className="erroStar">*</p>
              </div>
              <CustomInput
                name="confirm_password"
                autoComplete="off"
                placeholder="Enter confirm password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.confirm_password}
                formik={formik}
                type={confPassVisible ? "text" : "password"}
                endAdornment={
                  <IconButton
                    className="material-symbols-rounded"
                    onClick={() => setConfPassVisible(!confPassVisible)}
                  >
                    {confPassVisible ? "visibility_off" : "visibility"}
                  </IconButton>
                }
              />
            </div>

            <div className="col-md-12">
              <CustomButton label="Reset Password" type="submit" />
            </div>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default ResetPassword;
