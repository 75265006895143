import React, { useState } from "react";
import "./index.css";
import { useMediaQuery } from "@mui/material";
import SideNav from "../SideNav";
import Header from "../Header";
import { Outlet } from "react-router-dom";

const Layout = (props) => {
  const [sidenav, setSidenav] = useState(true);
  const [mobilenav, setMobilenav] = useState(false);

  const isMatches = useMediaQuery("(max-width:991px)");

  const nav = isMatches ? mobilenav : sidenav;

  const handleDrawerOpen = () =>
    isMatches ? setMobilenav(true) : setSidenav(true);

  const handleDrawerClose = () =>
    isMatches ? setMobilenav(false) : setSidenav(false);

  return (
    <div className="layoutWrapper">
      {props.hideLayout ? (
        <>
          <div className="sideBarWrapper"></div>
          <div className="viewPortWrapper overflow-auto">
            <div className="pageViewPort">{props.children}</div>
          </div>
        </>
      ) : (
        <>
          <div className="sideBarWrapper">
            <SideNav
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              nav={nav}
            />
          </div>
          <div className="viewPortWrapper overflow-auto">
            <div className="hdrWrapr">
              <Header
                handleDrawerOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
                nav={nav}
              />
            </div>
            <div className="pageViewPort">
              <Outlet />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Layout;
