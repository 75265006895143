import React, { useEffect, useState } from "react";
import CustomButton from "../../Components/Atoms/CustomButton";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import CategoryForm from "./CategoryForm";
import Tab from "../../Components/Molecules/Tabs";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "../../Components/Molecules/Pagination";
import NoRecords from "../../Components/Atoms/NoRecord";
import SettingsCondition from "./ConditionIndex";
import DoneIcon from "@mui/icons-material/Done";

import {
  getCategoryListRequest,
  putCategoryRequest,
  putCategoryResponseClear,
} from "../../Redux/Settings/Category/CategoryActions";
import ConditionForm from "./ConditionForm";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import toast from "react-hot-toast";
import { Edit } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import AvailableStatus from "../../Components/Atoms/AvailableStatus";

const SettingsCategory = () => {
  const dispatch = useDispatch();
  const {
    CategoryListPageResponse,
    CategoryListPageLoading,
    CategoryPutResponse,

    CategoryPostLoading,
    CategoryPutLoading,
  } = useSelector((state) => state.category);

  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("category");
  const [actionType, setActionType] = useState("new");
  const [categoryId, setCategoryId] = useState(null);

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const tabs = [
    { label: "Category", value: "category" },
    { label: "Condition", value: "condition" },
  ];

  const handleOpen = (type, id) => {
    setOpen(true);
    setActionType(type);
    setCategoryId(id);
  };
  const handleClose = () => {
    setOpen(false);
    setActionType(null);
    setCategoryId(null);
  };

  const [page, setPage] = useState(1);

  const searchSchema = {
    filters: {
      search: {
        value: "",
      },
    },
    limit: 10,
    page: page,
    sort: "",
  };

  const searchFormik = useFormik({
    initialValues: searchSchema,
    onSubmit: (values) => {
      setPage(1);
      return dispatch(getCategoryListRequest(values));
    },
  });

  useEffect(() => {
    dispatch(getCategoryListRequest({ ...searchFormik.values, page }));
  }, [CategoryPostLoading, CategoryPutLoading]);

  const toggleStatus = (id) => {
    const selectedItem = CategoryListPageResponse?.data?.find(
      (each) => each._id === id
    );

    if (selectedItem) {
      const updatedData = {
        ...selectedItem,
        status: selectedItem.status === true ? false : true,
      };

      dispatch(putCategoryRequest(updatedData, id));
    }
  };

  useEffect(() => {
    if (CategoryPutResponse?.success && CategoryPutResponse?.message) {
      toast.success(CategoryPutResponse?.message);
      handleClose();
    } else if (CategoryPutResponse?.response?.data) {
      toast.error(CategoryPutResponse?.response?.data?.message);
    }

    return () => {
      dispatch(putCategoryResponseClear());
    };
  }, [CategoryPutResponse]);

  return (
    <>
      <CustomDialog
        maxWidth="sm"
        title={
          selectedTab === "condition" && actionType === "edit"
            ? "Update Conditon"
            : selectedTab === "category" && actionType === "edit"
            ? "Update Category"
            : selectedTab === "category"
            ? "Add Category"
            : "Add Condition"
        }
        handleClose={() => {
          handleClose();
        }}
        open={open}
      >
        {selectedTab === "condition" ? (
          <ConditionForm
            handleClose={handleClose}
            selectedTab={selectedTab}
            actionType={actionType}
            categoryId={categoryId}
          />
        ) : (
          <CategoryForm
            handleClose={handleClose}
            actionType={actionType}
            categoryId={categoryId}
            selectedTab={selectedTab}
          />
        )}
      </CustomDialog>

      <div className="cntr">
        <h6>Sample</h6>

        <div className="hdrComp">
          <div>
            <Tab
              tabs={tabs}
              selectedTab={selectedTab}
              handleTabChange={handleTabChange}
            />
          </div>
          <div className="rtActnWrpr">
            <CustomButton
              label={
                selectedTab === "condition" ? "Add Conditon" : "Add Category"
              }
              size="small"
              onClick={() => handleOpen("new")}
            />
          </div>
        </div>

        {selectedTab === "category" && (
          <>
            {CategoryListPageLoading &&
            !CategoryListPageLoading?.data?.length > 0 ? (
              <div className="d-flex align-items-center justify-content-center">
                <CircularProgress size={25} />
              </div>
            ) : (
              <TablePagination
                showFilter={true}
                showSort={true}
                setPage={setPage}
                page={page}
                initialValues={searchSchema}
                formik={searchFormik}
                action={(values) => dispatch(getCategoryListRequest(values))}
                result={CategoryListPageResponse}
                count={CategoryListPageResponse?.data?.total_count}
              >
                <div className="tblWrpr">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Category</th>
                          <th>Status</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {CategoryListPageResponse?.data?.length > 0 ? (
                          CategoryListPageResponse?.data?.map((data, index) => {
                            return (
                              <tr key={data._id}>
                                <td>
                                  {searchFormik?.values?.limit * (page - 1) +
                                    index +
                                    1}
                                </td>
                                <td>{data.name}</td>

                                <td>
                                  {/* <span
                                    className={
                                      data?.status === true
                                        ? "text-success"
                                        : "text-danger"
                                    }
                                  >
                                    {data?.status === true
                                      ? "Active"
                                      : "In Active"}
                                  </span> */}
                                  <AvailableStatus
                                    status={
                                      //each?.max_quantity > 0 &&
                                      data?.status ? "Active" : "InActive"
                                    }
                                    //each?.max_quantity > 0 &&
                                    type={data?.status}
                                  />
                                </td>

                                <td>
                                  <div className="view">
                                    <button
                                      onClick={() => toggleStatus(data._id)}
                                    >
                                      {data.status === true ? (
                                        <RemoveCircleOutlineIcon />
                                      ) : (
                                        <DoneIcon />
                                      )}
                                    </button>
                                    <button
                                      className="employee-action-btn"
                                      onClick={() =>
                                        handleOpen("edit", data._id)
                                      }
                                    >
                                      <Edit />
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <NoRecords />
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </TablePagination>
            )}
          </>
        )}
        {selectedTab === "condition" && (
          <>
            <SettingsCondition
              handleOpen={handleOpen}
              handleClose={handleClose}
              setOpen={setOpen}
              categoryId={categoryId}
            />
          </>
        )}
      </div>
    </>
  );
};

export default SettingsCategory;
