import ChangePasswordType from "./ChangePasswordActionType";

const initialState = {
  ChangepasswordLoading: null,
  ChangepasswordResponse: null,
};

const ChangePasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case ChangePasswordType.CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        ChangepasswordLoading: true,
      };

    case ChangePasswordType.CHANGE_PASSWORD_RESPONSE:
      return {
        ...state,
        ChangepasswordLoading: false,
        ChangepasswordResponse: action.payload,
      };

    case ChangePasswordType.CHANGE_PASSWORD_CLEAR:
      return {
        ...state,
        ChangepasswordResponse: null,
      };

    default:
      return state;
  }
};

export default ChangePasswordReducer;
