import React, { useState } from "react";
import NoRecords from "../../Components/Atoms/NoRecord";
import TablePagination from "../../Components/Molecules/Pagination";
import { getSampleListRequest } from "../../Redux/Sample/SampleActions";
import { useDispatch } from "react-redux";
import moment from "moment";
import { CircularProgress } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SampleForm from "../Samples/SampleForm";
import CustomDialog from "../../Components/Molecules/CustomDialog";

const SearchFilterData = ({
  SampleListPageResponse,
  searchSample,
  searchFormik,
  SampleListPageLoading,
  type,
  setPage,
  page,
}) => {
  const dispatch = useDispatch();
  const [sample, setSample] = useState(false);
  const [sampleEditData, setSampleEditData] = useState(false);

  const handleSampleEditOpen = (data) => {
    setSample(true);
    setSampleEditData(data);
  };
  const handleSampleEditClose = () => {
    setSample(false);
    setSampleEditData(null);
  };

  function getStatusClassName(status) {
    switch (status) {
      case "IN_TESTING":
        return "text-primary";
      case "RETURNED":
        return "text-success";
      default:
        return "text-danger";
    }
  }

  function formatStatus(status) {
    return status
      ?.toLowerCase()
      ?.replace(/_/g, " ")
      ?.replace(/\b\w/g, (c) => c?.toUpperCase());
  }

  return (
    <>
      <CustomDialog
        maxWidth="md"
        title="Sample "
        handleClose={() => {
          handleSampleEditClose();
        }}
        open={sample}
      >
        <SampleForm
          handleClose={handleSampleEditClose}
          sampleData={sampleEditData}
          type="popup_edit"
        />
      </CustomDialog>
      ;
      <div className="dashFltrTble">
        <TablePagination
          showFilter={true}
          showSort={true}
          setPage={setPage}
          initialValues={searchSample}
          formik={searchFormik}
          action={(values) => dispatch(getSampleListRequest(values))}
          result={SampleListPageResponse}
          count={SampleListPageResponse?.data?.total_count}
        >
          {SampleListPageLoading && !SampleListPageLoading?.data?.length > 0 ? (
            <div className="d-flex align-items-center justify-content-center">
              <CircularProgress size={25} />
            </div>
          ) : (
            <div className="tblWrpr">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Sample&nbsp;Receipt</th>
                      <th>Manufacturer&nbsp;Name</th>
                      <th>Category</th>
                      <th>Model&nbsp;No.</th>
                      <th>Standard</th>
                      <th>SRF&nbsp;ID</th>
                      <th>Test&nbsp;Report&nbsp;No</th>
                      <th>ULR&nbsp;No.</th>
                      <th>Issued&nbsp;Date</th>
                      <th>Tested&nbsp;By</th>
                      <th>Sample&nbsp;Status</th>
                      <th>DC&nbsp;No.</th>
                      {/* <th>Actions</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {SampleListPageResponse?.data?.length > 0 ? (
                      SampleListPageResponse?.data?.map((data, index) => {
                        return (
                          <tr key={data._id}>
                            <td>
                              {searchFormik?.values?.limit * (page - 1) +
                                index +
                                1}
                            </td>
                            <td>
                              {moment(data?.received_date).format("DD/MM/YYYY")}
                            </td>
                            <td>{data.allot_manufacturer_name}</td>
                            <td>{data.product_category_ref?.name}</td>
                            <td>{data.model_no}</td>
                            <td>{data?.standard}</td>
                            <td>{data.allot_srf_id}</td>
                            <td>{data?.rpt_testreport_no}</td>
                            <td>{data?.rpt_ulr_no}</td>
                            <td>
                              {moment(data?.rpt_issue_date).format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                            <td>{data?.tested_by_ref?.first_name}</td>
                            {type === "view" ? (
                              <td>
                                <span
                                  className={
                                    data?.status === "REPORTS_ISSUED"
                                      ? "text-primary"
                                      : data?.status === "ALLOTTED"
                                      ? "text-success"
                                      : "text-danger"
                                  }
                                >
                                  {data?.status
                                    ?.toLowerCase()
                                    ?.replace(/_/g, " ")
                                    ?.replace(/\b\w/g, (c) => c?.toUpperCase())}
                                </span>
                              </td>
                            ) : (
                              <td>
                                <span
                                  className={getStatusClassName(
                                    data?.sub_status
                                  )}
                                >
                                  {formatStatus(data?.sub_status)}
                                </span>
                              </td>
                            )}

                            <td>{data.delivery_challan_no}</td>
                            {/* <td className="view"> */}
                            {/* <button
                                onClick={() => handleSampleEditOpen(data)}
                              >
                                <EditIcon />
                              </button> */}
                            {/* </td> */}
                          </tr>
                        );
                      })
                    ) : (
                      <NoRecords />
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </TablePagination>
      </div>
    </>
  );
};

export default SearchFilterData;
