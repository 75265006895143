import ForgotAndResetPasswordTypes from "./ForgotAndResetPasswordActionTypes";

//------forgot password POST---------------------

export const postForgotPasswordRequest = (payload) => ({
  type: ForgotAndResetPasswordTypes.POST_FORGOT_PASSWORD_REQUEST,
  payload: payload,
});

export const postForgotPasswordResponse = (payload) => ({
  type: ForgotAndResetPasswordTypes.POST_FORGOT_PASSWORD_RESPONSE,
  payload,
});

export const postForgotPasswordResponseClear = () => ({
  type: ForgotAndResetPasswordTypes.POST_FORGOT_PASSWORD_RESPONSE_CLEAR,
});

//------reset password POST----------------------

export const postResetPasswordRequest = (payload) => ({
  type: ForgotAndResetPasswordTypes.POST_RESET_PASSWORD_REQUEST,
  payload: payload,
});

export const postResetPasswordResponse = (payload) => ({
  type: ForgotAndResetPasswordTypes.POST_RESET_PASSWORD_RESPONSE,
  payload,
});

export const postResetPasswordResponseClear = () => ({
  type: ForgotAndResetPasswordTypes.POST_RESET_PASSWORD_RESPONSE_CLEAR,
});
