import React, { useEffect, useState } from "react";
import SearchInput from "../../Components/Atoms/SearchInput";
import Tab from "../../Components/Molecules/Tabs";
import UnAlloted from "./UnAlloted";
import ReportIssued from "./ReportIssued";
import DropdownMenu from "../../Components/Atoms/DropdownMenu";
import { Edit, KeyboardArrowDown } from "@mui/icons-material";
import { Button, CircularProgress, Drawer } from "@mui/material";
import {
  getSampleListRequest,
  putSampleRequest,
  putSampleResponseClear,
} from "../../Redux/Sample/SampleActions";
import { useDispatch, useSelector } from "react-redux";
import NoRecords from "../../Components/Atoms/NoRecord";
import { useFormik } from "formik";
import TablePagination from "../../Components/Molecules/Pagination";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import ReportIssuedForm from "./ReportIssuedForm";
import CustomButton from "../../Components/Atoms/CustomButton";
import ExportData, {
  handleDownloadFile,
} from "../../Components/Molecules/ExportData";
import CustomConfirmation from "../../Components/Atoms/CustomConfirmation";
import toast from "react-hot-toast";
import TableHeader from "../../Components/Atoms/TableHeader";
import moment from "moment";
import AllotForm from "./AllotForm";
import AllotedFilter from "./Filters/AllotedFilter";
import { ROLE } from "../../Constant";
import { getPurposeOfTestingDisplayName } from "../../Utils/getters";

const Samples = () => {
  const {
    SampleListPageResponse,
    SamplePutResponse,
    SamplePutLoading,
    SampleListPageLoading,
  } = useSelector((state) => state.sample);

  const { getUserResponse } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const [filter, setFilter] = useState(false);
  const [reset, setReset] = useState(false);
  const [selectedTab, setSelectedTab] = useState(
    getUserResponse?.data?.role === ROLE.ADMIN ? "unallot" : "allot"
  );
  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const tabs = [
    {
      label: "Unalloted Samples",
      value: "unallot",
      role: [ROLE.ADMIN],
    },
    {
      label: "Alloted Samples",
      value: "allot",
      role: [ROLE.ADMIN, ROLE.STANDARD],
    },
    {
      label: "Report Issued",
      value: "report",
      role: [ROLE.ADMIN, ROLE.STANDARD],
    },
  ];
  const [page, setPage] = useState(1);

  // const searchSample = {
  //   filters: {
  //     search: {
  //       value: "",
  //     },
  //     status: {
  //       value: "ALLOTTED",
  //     },
  //   },
  //   limit: 10,
  //   page: page,
  //   sort: "",
  // };

  // const searchFormik = useFormik({
  //   initialValues: searchSample,
  //   onSubmit: (values) => {
  //     setPage(1);
  //     return dispatch(getSampleListRequest(values));
  //   },
  // });

  const searchSample = {
    filters: {
      search: {
        value: "",
      },
      status: {
        value: "ALLOTTED",
      },
      product_category_ref: {
        value: [],
        type: "array",
      },
      tested_by_ref: {
        value: [],
        type: "array",
      },
      sample_condition_ref: {
        value: [],
        type: "array",
      },
      rpt_expiry_date: {
        value: [],
        type: "date_range",
      },
      received_date: {
        value: [],
        type: "date_range",
      },
      received_by_ref: {
        value: [],
        type: "array",
      },
    },
    limit: 10,
    page: page,
    sort: "",
  };
  const searchFormik = useFormik({
    initialValues: searchSample,

    onSubmit: (values) => {
      if (
        values.filters.rpt_expiry_date.value[0] &&
        !values.filters.rpt_expiry_date.value[1]
      ) {
        toast.error("Expiry to date is required");
        return;
      } else if (
        !values.filters.rpt_expiry_date.value[0] &&
        values.filters.rpt_expiry_date.value[1]
      ) {
        toast.error("Expiry from date is required");
        return;
      } else if (
        values.filters.rpt_expiry_date.value[0] >
        values.filters.rpt_expiry_date.value[1]
      ) {
        toast.error("Expiry to date should not less than from date");
        return;
      } else if (
        values.filters.received_date.value[0] &&
        !values.filters.received_date.value[1]
      ) {
        toast.error("Received to date is required");
        return;
      } else if (
        !values.filters.received_date.value[0] &&
        values.filters.received_date.value[1]
      ) {
        toast.error("Received from date is required");
        return;
      } else if (
        values.filters.received_date.value[0] >
        values.filters.received_date.value[1]
      ) {
        toast.error("Received to date should not less than from date");
        return;
      }
      setPage(1);
      setReset(true);
      return dispatch(getSampleListRequest(payload));
    },
  });
  const payload = {
    filters: {
      search: {
        value: searchFormik.values.filters.search?.value,
      },
      status: {
        value: "ALLOTTED",
      },
      product_category_ref: {
        value:
          searchFormik.values.product_category_ref?.value?.map(
            (el) => el?._id
          ) || [],
        type: "array",
      },

      sample_condition_ref: {
        value:
          searchFormik.values.sample_condition_ref?.value?.map(
            (el) => el?._id
          ) || [],
        type: "array",
      },

      tested_by_ref: {
        value:
          searchFormik.values.tested_by_ref?.value?.map((el) => el?._id) || [],
        type: "array",
      },
      received_by_ref: {
        value:
          searchFormik.values.received_by_ref?.value?.map((el) => el?._id) ||
          [],
        type: "array",
      },
      rpt_expiry_date: {
        value:
          searchFormik.values.filters.rpt_expiry_date?.value[0] &&
          searchFormik.values.filters.rpt_expiry_date?.value[1]
            ? [
                searchFormik.values.filters.rpt_expiry_date?.value[0],
                searchFormik.values.filters.rpt_expiry_date?.value[1],
              ]
            : [],
        type: "date_range",
      },
      received_date: {
        value:
          searchFormik.values.filters.received_date?.value[0] &&
          searchFormik.values.filters.received_date?.value[1]
            ? [
                searchFormik.values.filters.received_date?.value[0],
                searchFormik.values.filters.received_date?.value[1],
              ]
            : [],
        type: "date_range",
      },
    },
    limit: 10,
    page: 1,
    sort: "",
  };

  useEffect(() => {
    if (selectedTab == "allot") {
      dispatch(getSampleListRequest(payload));
    }
  }, [selectedTab, SamplePutLoading, reset]);

  const [open, setOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [id, setId] = useState(null);

  const handleStatusChange = (status, id) => {
    setSelectedStatus(status);
    setId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedStatus(null);
    setId(null);
    setOpen(false);
  };

  useEffect(() => {
    if (SamplePutResponse?.success && SamplePutResponse?.message) {
      toast.success(SamplePutResponse?.message);
    } else if (SamplePutResponse?.response?.data) {
      toast.error(SamplePutResponse?.response?.data?.message);
    }

    return () => {
      dispatch(putSampleResponseClear());
    };
  }, [SamplePutResponse]);

  const headers = [
    "received_date",
    "end_date",
    "applicant_name",
    "product_category_ref",
    "model_no",
    "no_of_samples_received",
    "standard",
    "allot_srf_id",
    "rpt_testreport_no",
    "rpt_issue_date",
    "tested_by_ref",
    "rpt_expiry_date",
    "status",
    "sub_status",
  ];
  const values = {
    filters: searchFormik.values.filters,
  };

  const handleExportButtonClick = () => {
    const date = moment(new Date()).format("DD_MM_YYYY");
    handleDownloadFile({
      url: "sample/report/get_report",
      filename: `allotted_${date}.xlsx`,
      payload: { headers, ...values },
      method: "POST",
    });
  };

  const [allot, setAllot] = useState(false);
  const [allotEditData, setAllotEditData] = useState(false);

  const handleAllotEditOpen = (data) => {
    setAllot(true);
    setAllotEditData(data);
    setId(data?._id);
  };
  const handleAllotEditClose = () => {
    setAllot(false);
    setAllotEditData(null);
    setId(null);
  };

  const handleFilter = () => {
    searchFormik.resetForm();
    setReset(false);
  };

  const openFilter = () => {
    setFilter(true);
  };
  const closeFilter = () => {
    setFilter(false);
  };
  return (
    <>
      <Drawer anchor="right" open={filter} onClose={closeFilter}>
        <AllotedFilter
          searchFormik={searchFormik}
          handleFilterClose={closeFilter}
          open={filter}
          handleFilter={handleFilter}
        />
      </Drawer>

      {selectedStatus === "reportissued" && (
        <CustomDialog
          maxWidth="sm"
          title={"Report Issued"}
          handleClose={handleClose}
          open={open}
        >
          <ReportIssuedForm id={id} handleClose={handleClose} />
        </CustomDialog>
      )}

      <CustomDialog
        maxWidth="md"
        title={allotEditData ? "Edit Allot Sample" : "Add Allot Sample"}
        handleClose={() => {
          handleAllotEditClose();
        }}
        open={allot}
      >
        <AllotForm
          handleClose={handleAllotEditClose}
          sampleData={allotEditData}
          id={id}
          type="popup_edit"
        />
      </CustomDialog>
      <div className="cntr">
        <h6>Sample</h6>

        {selectedTab == "allot" && (
          <>
            <TableHeader
              role={getUserResponse?.data?.role}
              tabs={tabs}
              selectedTab={selectedTab}
              handleTabChange={handleTabChange}
              searchFormik={searchFormik}
              showTab={true}
            >
              <SearchInput
                value={searchFormik.values.filters.search.value}
                name={"filters.search.value"}
                onChange={searchFormik.handleChange}
                handleSubmit={searchFormik.handleSubmit}
                searchFormik={searchFormik}
                searchSample={searchSample}
              />
              {/* <ExportData /> */}
              <CustomButton
                onClick={() => handleExportButtonClick()}
                label={"Export"}
                size="small"
              />

              <CustomButton
                onClick={openFilter}
                label={"Filter"}
                size="small"
              />
            </TableHeader>
            {SampleListPageLoading &&
            !SampleListPageLoading?.data?.length > 0 ? (
              <div className="d-flex align-items-center justify-content-center">
                <CircularProgress size={25} />
              </div>
            ) : (
              <TablePagination
                showFilter={true}
                showSort={true}
                setPage={setPage}
                page={page}
                initialValues={searchSample}
                formik={searchFormik}
                action={(values) => dispatch(getSampleListRequest(values))}
                result={SampleListPageResponse}
                count={SampleListPageResponse?.data?.total_count}
              >
                <div className="tblWrpr">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>S.No</th>
                          <th>Rcvd.&nbsp;Date</th>
                          <th>End&nbsp;Date</th>
                          {getUserResponse?.data?.role === ROLE.ADMIN && (
                            <th>Applicant&nbsp;Name</th>
                          )}
                          <th>Category</th>
                          <th>Model&nbsp;No.</th>
                          <th>No.&nbsp;Samples&nbsp;Rcvd</th>
                          <th>Condition</th>
                          <th>Rcvd.&nbsp;By</th>
                          <th>Purpose&nbsp;of&nbsp;Testing</th>
                          <th>SRF&nbsp;ID</th>
                          <th>Test&nbsp;Req.&nbsp;No</th>
                          <th>Encoded&nbsp;Code</th>
                          <th>Barcode&nbsp;No</th>
                          <th>Manufacturer&nbsp;Name</th>
                          <th>Manufacturer&nbsp;Address</th>
                          <th>Assigned&nbsp;To</th>
                          <th>Status</th>

                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {SampleListPageResponse?.data?.length > 0 ? (
                          SampleListPageResponse?.data?.map((data, index) => {
                            return (
                              <tr key={data._id}>
                                <td>
                                  {searchFormik?.values?.limit * (page - 1) +
                                    index +
                                    1}
                                </td>
                                <td>
                                  {moment(data?.received_date).format(
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                                <td>
                                  {data?.end_date
                                    ? moment(data?.end_date).format(
                                        "DD/MM/YYYY"
                                      )
                                    : "-"}
                                </td>
                                {getUserResponse?.data?.role === ROLE.ADMIN && (
                                  <td>{data.applicant_name}</td>
                                )}
                                <td>{data.product_category_ref?.name}</td>
                                <td>{data.model_no}</td>
                                <td>{data.no_of_samples_received}</td>
                                <td className="td">
                                  {data.sample_condition_ref?.name}
                                </td>
                                <td>{data.received_by_ref?.first_name}</td>
                                <td>
                                  {getPurposeOfTestingDisplayName(
                                    data?.purpose_of_testing
                                  )}
                                  {/* {data?.purpose_of_testing ===
                                  "BIS_MODIFICATION"
                                    ? "BIS Modification"
                                    : data?.purpose_of_testing ===
                                      "BIS_FULLTESTING"
                                    ? "BIS Fulltesting"
                                    : data?.purpose_of_testing ===
                                      "NABL_PERFORMANCE"
                                    ? "NABL Perfomance"
                                    : data?.purpose_of_testing === "R_AND_D"
                                    ? "R&D"
                                    : data?.purpose_of_testing === "OTHERS"
                                    ? "Others"
                                    : ""} */}
                                </td>
                                <td>{data.allot_srf_id}</td>
                                <td>{data.allot_testreq_no}</td>
                                <td>{data.allot_encoded_code}</td>
                                <td>{data.allot_barcode_no}</td>
                                <td>{data.allot_manufacturer_name}</td>
                                <td>
                                  {data.allot_manufacturer_address || "-"}
                                </td>
                                <td>{data?.assigned_to_ref?.first_name}</td>
                                <td>
                                  <span
                                    className={
                                      data?.sub_status === "IN_TESTING"
                                        ? "text-primary"
                                        : "text-danger"
                                    }
                                  >
                                    {(
                                      data?.sub_status
                                        ?.toLowerCase()
                                        ?.replace(/_/g, " ") || ""
                                    ).replace(/^\w/, (c) => c.toUpperCase())}
                                  </span>
                                </td>
                                <td className="">
                                  <div className="d-flex gap-3">
                                    <div className="view">
                                      <button
                                        onClick={() =>
                                          handleAllotEditOpen(data)
                                        }
                                      >
                                        <Edit />
                                      </button>
                                    </div>

                                    <DropdownMenu
                                      menuList={[
                                        {
                                          label: "In-Testing",
                                          onClick: () => {
                                            const confirmTesting =
                                              window.confirm(
                                                "Are you sure you want to mark this as In-Testing?"
                                              );
                                            if (confirmTesting) {
                                              dispatch(
                                                putSampleRequest({
                                                  sub_status: "IN_TESTING",
                                                  id: data._id,
                                                })
                                              );
                                            }
                                          },
                                        },
                                        {
                                          label: "Report Issued",
                                          onClick: () =>
                                            handleStatusChange(
                                              "reportissued",
                                              data._id
                                            ),
                                        },
                                        {
                                          label: "Cancelled",
                                          onClick: () => {
                                            const confirmTesting =
                                              window.confirm(
                                                "Are you sure you want to mark this as Cancelled?"
                                              );
                                            if (confirmTesting) {
                                              dispatch(
                                                putSampleRequest({
                                                  sub_status: "CANCELLED",
                                                  id: data._id,
                                                })
                                              );
                                            }
                                          },
                                        },
                                      ]}
                                    >
                                      <Button
                                        variant="outlined"
                                        color="secondary"
                                      >
                                        <span
                                          className="text-start text-capitalize"
                                          style={{ width: "120px" }}
                                        >
                                          Updated Status
                                        </span>
                                        <KeyboardArrowDown className="ms-2" />
                                      </Button>
                                    </DropdownMenu>
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <NoRecords />
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </TablePagination>
            )}
          </>
        )}
      </div>

      {getUserResponse?.data?.role === ROLE.ADMIN &&
        selectedTab == "unallot" && (
          <UnAlloted
            tabs={tabs}
            selectedTab={selectedTab}
            handleTabChange={handleTabChange}
          />
        )}
      {selectedTab == "report" && (
        <ReportIssued
          tabs={tabs}
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
        />
      )}
    </>
  );
};

export default Samples;
