import { takeLatest, put, call } from "redux-saga/effects";
import ForgotAndResetPasswordTypes from "./ForgotAndResetPasswordActionTypes";
import {
  postForgotPasswordResponse,
  postResetPasswordResponse,
} from "./ForgotAndResetPasswordActions";
import { axiosPost } from "../../Utils/axios";

export function* onPostForgotPassword({ payload }) {
  try {
    let response = yield call(() =>
      axiosPost("auth/forgot_password", payload).then((res) => res?.data)
    );
    yield put(postForgotPasswordResponse(response));
  } catch (error) {
    yield put(postForgotPasswordResponse(error));
  }
}

export function* onPostResetPassword({ payload }) {
  try {
    let response = yield call(() =>
      axiosPost(
        `auth/reset_password?token=${payload.token}&id=${payload.id}`,
        payload.values
      ).then((response) => response?.data)
    );
    yield put(postResetPasswordResponse(response));
  } catch (error) {
    yield put(postResetPasswordResponse(error));
  }
}

export function* ForgotAndResetPasswordWatcherSaga() {
  yield takeLatest(
    ForgotAndResetPasswordTypes.POST_FORGOT_PASSWORD_REQUEST,
    onPostForgotPassword
  );
  yield takeLatest(
    ForgotAndResetPasswordTypes.POST_RESET_PASSWORD_REQUEST,
    onPostResetPassword
  );
}
