export default {
  GET_CONDITION_LIST_REQUEST: "GET_CONDITION_LIST_REQUEST",
  GET_CONDITION_LIST_RESPONSE: "GET_CONDITION_LIST_RESPONSE",

  GET_CONDITION_BYID_REQUEST: "GET_CONDITION_BYID_REQUEST",
  GET_CONDITION_BYID_RESPONSE: "GET_CONDITION_BYID_RESPONSE",
  GET_CONDITION_BYID_RESCLEAR: "GET_CONDITION_BYID_RESCLEAR",

  POST_CONDITION_REQUEST: "POST_CONDITION_REQUEST",
  POST_CONDITION_RESPONSE: "POST_CONDITION_RESPONSE",
  POST_CONDITION_RESPONSE_CLEAR: "POST_CONDITION_RESPONSE_CLEAR",

  PUT_CONDITION_REQUEST: "PUT_CONDITION_REQUEST",
  PUT_CONDITION_RESPONSE: "PUT_CONDITION_RESPONSE",
  PUT_CONDITION_RESPONSE_CLEAR: "PUT_CONDITION_RESPONSE_CLEAR",
};
