import React, { useEffect } from "react";
import CustomInput from "../../../Components/Atoms/CustomInput";
import CustomButton from "../../../Components/Atoms/CustomButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoryByIdRequest,
  getCategoryByIdResponseClear,
  postCategoryRequest,
  postCategoryResponseClear,
  putCategoryRequest,
} from "../../../Redux/Settings/Category/CategoryActions";
import toast from "react-hot-toast";
import { expression } from "../../../Constant";

const CategoryForm = ({ handleClose, actionType, categoryId, selectedTab }) => {
  const dispatch = useDispatch();
  const {
    CategoryPostResponse,
    CategoryByIdPageResponse,
    CategoryByIdPageLoading,
    CategoryPostLoading,
    CategoryPutLoading,
  } = useSelector((state) => state.category);

  const validationArray = Yup.object({
    name: Yup.string()
      .required("Required")
      .min(3, "Minimum of 3 characters")
      .max(50, "Maximum of 50 characters")
      .matches(expression.min_one_char, "Category should have a character")
      .matches(expression.space, "Category should not contain spaces"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationArray,
    onSubmit: (values) => {
      if (actionType === "new") {
        dispatch(postCategoryRequest(values));
      } else {
        dispatch(putCategoryRequest(values));
      }
    },
  });

  useEffect(() => {
    if (CategoryPostResponse?.success && CategoryPostResponse?.message) {
      toast.success(CategoryPostResponse?.message);
      formik.resetForm();
      handleClose();
    } else if (CategoryPostResponse?.response?.data) {
      toast.error(CategoryPostResponse?.response?.data?.message);
    }

    return () => {
      dispatch(postCategoryResponseClear());
    };
  }, [CategoryPostResponse]);
  useEffect(() => {
    if (actionType === "edit") {
      dispatch(getCategoryByIdRequest(categoryId));
    }
    return () => {
      dispatch(getCategoryByIdResponseClear());
    };
  }, [actionType]);

  useEffect(() => {
    if (actionType === "edit" && CategoryByIdPageResponse?.data) {
      formik.setFieldValue("_id", CategoryByIdPageResponse?.data?._id);
      formik.setFieldValue("name", CategoryByIdPageResponse?.data?.name);
    }
  }, [CategoryByIdPageResponse, CategoryByIdPageLoading]);
  return (
    <>
      <div className="">
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex">
                <p className="mb-1">Category Name</p>
                <p className="erroStar">*</p>
              </div>
              <CustomInput
                type="text"
                name="name"
                placeholder="Enter category name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
              />
            </div>

            <div className="btnActnBar">
              <CustomButton
                btnType="secondary"
                onClick={() => handleClose()}
                label={"Cancel"}
              />
              <CustomButton
                label={
                  selectedTab === "category" && actionType === "edit"
                    ? "Update"
                    : selectedTab === "category" && actionType === "edit"
                    ? "Update"
                    : selectedTab === "category"
                    ? "Add"
                    : "Add"
                }
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default CategoryForm;
