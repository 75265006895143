import { takeLatest, put, call } from "redux-saga/effects";
import sampleListTypes from "./SampleActionTypes";
import axios from "axios";

import {
  getSampleListResponse,
  getSamplestatusResponse,
  postSampleResponse,
  putSampleResponse,
} from "./SampleActions";
import { UnautherizedResponse } from "../GetUser/GetUserActions";
import { axiosGet, axiosPost, axiosPut } from "../../Utils/axios";

export function* onGetsampleList({ payload }) {
  try {
    let response = yield call(() =>
      axiosPost("sample/index", payload).then((res) => res?.data)
    );

    yield put(getSampleListResponse(response));
  } catch (error) {
    yield put(UnautherizedResponse(error));
  }
}

export function* onPostsample({ payload }) {
  try {
    let response = yield call(() =>
      axiosPost("sample/new", payload).then((res) => res?.data)
    );
    yield put(postSampleResponse(response));
  } catch (error) {
    yield put(UnautherizedResponse(error));
  }
}

export function* onGetSampleStatus({ payload }) {
  try {
    let response = yield call(() =>
      axiosGet("sample/stats", payload).then((res) => res?.data)
    );
    yield put(getSamplestatusResponse(response));
  } catch (error) {
    yield put(UnautherizedResponse(error));
  }
}

export function* onPutsample({ payload }) {
  try {
    let response = yield call(() =>
      axiosPut(`sample/${payload.id}`, payload).then((res) => res?.data)
    );
    yield put(putSampleResponse(response));
  } catch (error) {
    yield put(UnautherizedResponse(error));
  }
}

export function* sampleListWatcherSaga() {
  yield takeLatest(sampleListTypes.GET_SAMPLE_LIST_REQUEST, onGetsampleList);
  yield takeLatest(
    sampleListTypes.GET_SAMPLE_STATUS_REQUEST,
    onGetSampleStatus
  );

  yield takeLatest(sampleListTypes.POST_SAMPLE_REQUEST, onPostsample);

  yield takeLatest(sampleListTypes.PUT_SAMPLE_REQUEST, onPutsample);
}
