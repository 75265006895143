import LoginTypes from "./LoginActionTypes";

//-------login POST---------------------
export const postLoginRequest = (payload) => ({
  type: LoginTypes.POST_LOGIN_REQUEST,
  payload: payload,
});

export const postLoginResponse = (payload) => ({
  type: LoginTypes.POST_LOGIN_RESPONSE,
  payload: payload,
});

export const postLoginResponseClear = (payload) => ({
  type: LoginTypes.POST_LOGIN_RESPONSE_CLEAR,
});
