export default {
  GET_EMPLOYEE_LIST_REQUEST: "GET_EMPLOYEE_LIST_REQUEST",
  GET_EMPLOYEE_LIST_RESPONSE: "GET_EMPLOYEE_LIST_RESPONSE",

  POST_EMPLOYEE_REQUEST: "POST_EMPLOYEE_REQUEST",
  POST_EMPLOYEE_RESPONSE: "POST_EMPLOYEE_RESPONSE",
  POST_EMPLOYEE_RESPONSE_CLEAR: "POST_EMPLOYEE_RESPONSE_CLEAR",

  PUT_EMPLOYEE_REQUEST: "PUT_EMPLOYEE_REQUEST",
  PUT_EMPLOYEE_RESPONSE: "PUT_EMPLOYEE_RESPONSE",
  PUT_EMPLOYEE_RESPONSE_CLEAR: "PUT_EMPLOYEE_RESPONSE_CLEAR",
};
