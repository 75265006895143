import React, { useEffect, useState } from "react";

import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import {
  getConditionListRequest,
  putConditionRequest,
  putConditionResponseClear,
} from "../../Redux/Settings/Condition/ConditionActions";
import TablePagination from "../../Components/Molecules/Pagination";
import NoRecords from "../../Components/Atoms/NoRecord";
import toast from "react-hot-toast";
import { Edit } from "@mui/icons-material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { CircularProgress } from "@mui/material";
import AvailableStatus from "../../Components/Atoms/AvailableStatus";

const SettingsCondition = ({ handleOpen, handleClose }) => {
  const dispatch = useDispatch();
  const {
    ConditionListPageResponse,
    ConditionPutResponse,
    ConditionListPageLoading,
    ConditionPostLoading,
    ConditionPutLoading,
  } = useSelector((state) => state.condition);

  const [page, setPage] = useState(1);
  const searchSchema = {
    filters: {
      search: {
        value: "",
      },
    },
    limit: 10,
    page: page,
    sort: "",
  };

  const searchFormik = useFormik({
    initialValues: searchSchema,
    onSubmit: (values) => {
      setPage(1);
      return dispatch(getConditionListRequest(values));
    },
  });

  useEffect(() => {
    dispatch(getConditionListRequest({ ...searchFormik.values, page }));
  }, [page, ConditionPostLoading, ConditionPutLoading]);

  const toggleStatus = (id) => {
    const selectedItem = ConditionListPageResponse?.data?.find(
      (each) => each._id === id
    );

    if (selectedItem) {
      const updatedData = {
        ...selectedItem,
        status: selectedItem.status === true ? false : true,
      };

      dispatch(putConditionRequest(updatedData, id));
    }
  };

  useEffect(() => {
    if (ConditionPutResponse?.success && ConditionPutResponse?.message) {
      toast.success(ConditionPutResponse?.message);
      handleClose();
    } else if (ConditionPutResponse?.response?.data) {
      toast.error(ConditionPutResponse?.response?.data?.message);
    }

    return () => {
      dispatch(putConditionResponseClear());
    };
  }, [ConditionPutResponse]);

  return (
    <>
      {ConditionListPageLoading &&
      !ConditionListPageLoading?.data?.length > 0 ? (
        <div className="d-flex align-items-center justify-content-center">
          <CircularProgress size={25} />
        </div>
      ) : (
        <TablePagination
          showFilter={true}
          showSort={true}
          setPage={setPage}
          page={page}
          initialValues={searchSchema}
          formik={searchFormik}
          action={(values) => dispatch(getConditionListRequest(values))}
          result={ConditionListPageResponse}
          count={ConditionListPageResponse?.data?.total_count}
        >
          <div className="tblWrpr">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Condition</th>
                    <th>Status</th>

                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {ConditionListPageResponse?.data?.length > 0 ? (
                    ConditionListPageResponse?.data?.map((data, index) => {
                      return (
                        <tr key={data._id}>
                          <td>
                            {searchFormik?.values?.limit * (page - 1) +
                              index +
                              1}
                          </td>
                          <td>{data.name}</td>

                          <td>
                            {/* <span
                              className={
                                data?.status === true
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {data?.status === true ? "Active" : "In Active"}
                            </span> */}
                            <AvailableStatus
                              status={
                                //each?.max_quantity > 0 &&
                                data?.status ? "Active" : "InActive"
                              }
                              //each?.max_quantity > 0 &&
                              type={data?.status}
                            />
                          </td>

                          <td>
                            <div className="view">
                              <button onClick={() => toggleStatus(data._id)}>
                                {data.status === true ? (
                                  <RemoveCircleOutlineIcon />
                                ) : (
                                  <DoneIcon />
                                )}
                              </button>
                              <button
                                onClick={() => handleOpen("edit", data._id)}
                              >
                                <Edit />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <NoRecords />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </TablePagination>
      )}
    </>
  );
};

export default SettingsCondition;
