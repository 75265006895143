import SampleListTypes from "./SampleActionTypes";

//------sample GET------------------------
export const getSampleListRequest = (payload) => ({
  type: SampleListTypes.GET_SAMPLE_LIST_REQUEST,
  payload: payload,
});

export const getSampleListResponse = (payload) => ({
  type: SampleListTypes.GET_SAMPLE_LIST_RESPONSE,
  payload: payload,
});
export const getSampleListResClear = () => ({
  type: SampleListTypes.GET_SAMPLE_LIST_RESET,
});

//--------sample POST--------------------------
export const postSampleRequest = (payload) => ({
  type: SampleListTypes.POST_SAMPLE_REQUEST,
  payload: payload,
});

export const postSampleResponse = (payload) => ({
  type: SampleListTypes.POST_SAMPLE_RESPONSE,
  payload: payload,
});

export const postSampleResponseClear = () => ({
  type: SampleListTypes.POST_SAMPLE_RESPONSE_CLEAR,
});

//--------sample PUT--------------------------------
export const putSampleRequest = (payload) => ({
  type: SampleListTypes.PUT_SAMPLE_REQUEST,
  payload: payload,
});

export const putSampleResponse = (payload) => ({
  type: SampleListTypes.PUT_SAMPLE_RESPONSE,
  payload: payload,
});

export const putSampleResponseClear = () => ({
  type: SampleListTypes.PUT_SAMPLE_RESPONSE_CLEAR,
});

export const getSampleStatusRequest = (payload) => ({
  type: SampleListTypes.GET_SAMPLE_STATUS_REQUEST,
});

export const getSamplestatusResponse = (payload) => ({
  type: SampleListTypes.GET_SAMPLE_STATUS_RESPONSE,
  payload: payload,
});
