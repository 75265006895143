import React, { useEffect, useState } from "react";
import "./index.css";
import { Avatar, Button, IconButton, useMediaQuery } from "@mui/material";
import toast from "react-hot-toast";

import {
  Edit,
  KeyboardArrowDown,
  LockOpen,
  LockReset,
  Menu,
  NotificationAdd,
  NotificationsOff,
} from "@mui/icons-material";

import LockResetIcon from "@mui/icons-material/LockReset";

import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import { Link, useNavigate } from "react-router-dom";
import { MenuOpen } from "@mui/icons-material";
import DropdownMenu from "../../Atoms/DropdownMenu";
import UserChangePassword from "../../../Views/Login/ChangePassword";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserRequest,
  getUserResponseClear,
} from "../../../Redux/GetUser/GetUserActions";

const Header = ({ handleDrawerOpen, handleDrawerClose, nav }) => {
  const dispatch = useDispatch();
  const { getUserResponse } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const isMatches = useMediaQuery("(max-width:991px)");
  const handleLogout = () => {
    window.localStorage.removeItem("token");
    dispatch(getUserResponseClear());
    navigate("/login");
    toast.success("User logged out successfully");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [CurruserChangePassword, setCurrUserChangePassword] = useState({
    mount: false,
    id: undefined,
    name: "",
  });

  const handleChangePasswordClose = () => {
    setCurrUserChangePassword({ mount: false, id: "", name: "" });
  };

  const handleChangePasswordOpen = (mount, id, name) => {
    setCurrUserChangePassword({ mount, id, name });
  };

  return (
    <div className="layout_header">
      {isMatches && (
        <IconButton
          color="primary"
          size="small"
          onClick={() => (nav ? handleDrawerClose() : handleDrawerOpen())}
        >
          {nav ? <MenuOpen fontSize="small" /> : <Menu fontSize="small" />}
          {/* <MenuOpen /> */}
        </IconButton>
      )}

      <div className="rtHdrVw">
        <DropdownMenu
          MenuClassName="prflDrop"
          menuList={[
            {
              label: (
                <>
                  <ListItemIcon>
                    <LockResetIcon />
                  </ListItemIcon>
                  update password
                </>
              ),

              onClick: () => handleChangePasswordOpen(true),
              // hidden: user?.role?.name === "invite",
            },

            {
              label: (
                <>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </>
              ),
              onClick: () => handleLogout(),
            },
          ]}
        >
          <Button className="acntDropBtn" color="inherit">
            <Avatar sx={{ width: 30, height: 30, fontSize: "small" }}>
              {getUserResponse?.data?.first_name
                ? getUserResponse.data.first_name.charAt(0)
                : ""}
            </Avatar>

            <div className="acntNmInfo">
              <p>{getUserResponse?.data?.first_name}</p>
            </div>
            <span className="material-symbols-rounded">expand_more</span>
          </Button>
        </DropdownMenu>
      </div>
      <UserChangePassword
        open={CurruserChangePassword.mount}
        title={
          <div className="d-flex align-items-center">
            Change Password {CurruserChangePassword.name}
          </div>
        }
        id={CurruserChangePassword.id}
        handleClose={handleChangePasswordClose}
      />
    </div>
  );
};

export default Header;
