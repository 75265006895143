import {
  Country as country_api,
  State as state_api,
  City as city_api,
} from "country-state-city";

export const countryList = country_api.getAllCountries();

export const stateList = state_api;

export const cityList = city_api;

export const expression = {
  email: /^([a-zA-Z0-9_+\-\.]+)@([a-zA-Z\.]+)\.([a-zA-Z]{2,5})$/,
  space:
    /^[^\s].+([A-Za-z0-9!+@#$%^&*()_,/.-]+\s)*[A-Za-z0-9!+@#$%^&_(),/.-]+$/,
  Without_space: /^(?!\s).+(?<!\s)$/,
  space_message: "should not contain spaces",
  min_one_char: /^(\w| )*[A-Za-z](\w| )*$/,
  zipcode: /^[0-9-]*$/,
  char: /^[A-Za-z\s]+$/,
  percentage: /\b(?<!\.)(?!0+(?:\.0+)?%)(?:\d|[1-9]\d|100)(?:(?<!100)\.\d+)?%/,
};

export const PURPOSE_OF_TESTING_DATA = [
  { name: "BIS FULL TESTING", value: "BIS_FULLTESTING" },
  { name: "BIS MODIFICATION", value: "BIS_MODIFICATION" },
  { name: "NABL PERFORMANCE", value: "NABL_PERFORMANCE" },
  { name: "MEITY SURVEILANCE ORDER", value: "MEITY_SUR_ORDER" },
  { name: "R&D", value: "R_AND_D" },
  { name: "OTHERS", value: "OTHERS" },
];

export const role = [
  { name: "Admin", value: "ADMIN" },
  { name: "Standard", value: "STANDARD" },
];

export const ROLE = {
  ADMIN: "ADMIN",
  STANDARD: "STANDARD",
};
