import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import SearchInput from "../../../Components/Atoms/SearchInput";
import { Autocomplete, TextField } from "@mui/material";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomInput from "../../../Components/Atoms/CustomInput";
import { getCategoryListRequest } from "../../../Redux/Settings/Category/CategoryActions";
import { List } from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import { getConditionListRequest } from "../../../Redux/Settings/Condition/ConditionActions";
import { getEmployeeListRequest } from "../../../Redux/Employees/EmployeesActions";
import { ROLE } from "../../../Constant";

export default function UnAllotedFilter(props) {
  const dispatch = useDispatch();

  const {
    searchFormik,
    usersDropdownList,
    subcatDropdownList,
    handleFilterClose,
    handleRadio,
    radio,
    open,
    handleFilter,
  } = props;

  const handleChangeClear = (e, value) => {
    searchFormik.handleChange(e);
    searchFormik.setFieldValue(value, []);
  };
  const { CategoryListPageResponse } = useSelector((state) => state.category);
  const { ConditionListPageResponse } = useSelector((state) => state.condition);
  const { getUserResponse } = useSelector((state) => state.user);
  const { EmployeeListPageResponse } = useSelector((state) => state.employee);
  useEffect(() => {
    dispatch(
      getCategoryListRequest({
        filters: {
          search: {
            value: "",
          },
        },
        limit: "",
        page: "",
        sort: "name:asc",
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      getConditionListRequest({
        filters: {
          search: {
            value: "",
          },
        },
        limit: "",
        page: "",
        sort: "name:asc",
      })
    );
  }, []);

  useEffect(() => {
    dispatch(
      getEmployeeListRequest({
        filters: {
          search: {
            value: "",
          },
        },
        limit: "",
        page: "",
        sort: "first_name:asc",
      })
    );
  }, []);

  return (
    <div className="MainFilter">
      <div className="d-flex justify-content-between align-items-center">
        <p className="title">Unalloted Filter</p>
        <Close style={{ cursor: "pointer" }} onClick={handleFilterClose} />
      </div>
      <form onSubmit={searchFormik.handleSubmit} className="filterTable">
        <div className="w-100">
          <div className="customInputWrpr mt-5">
            <p className="mb-1">Product Category</p>
            <Autocomplete
              name="product_category_ref.value"
              multiple
              id="product_category_ref.value"
              options={CategoryListPageResponse?.data || []}
              className="w-100"
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?._id} //
              defaultValue={[CategoryListPageResponse?.data[0] || []]}
              filterSelectedOptions
              size="small"
              limitTags={2}
              onChange={(event, newValue) => {
                searchFormik.setFieldValue(
                  "product_category_ref.value",
                  newValue
                );
              }}
              onBlur={searchFormik.handleBlur}
              value={searchFormik.values?.product_category_ref?.value || []}
              renderInput={(params) => <TextField {...params} />}
            />
            {/* <CustomInput /> */}
          </div>

          {/* <div className="customInputWrpr mt-3">
            <p className="mb-1">Sample Condition</p>
            <Autocomplete
              name="sample_condition_ref.value"
              multiple
              id="sample_condition_ref.value"
              options={ConditionListPageResponse?.data || []}
              className="w-100"
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?._id} //
              defaultValue={[ConditionListPageResponse?.data[0] || []]}
              filterSelectedOptions
              size="small"
              limitTags={2}
              onChange={(event, newValue) => {
                searchFormik.setFieldValue(
                  "sample_condition_ref.value",
                  newValue
                );
              }}
              onBlur={searchFormik.handleBlur}
              value={searchFormik.values?.sample_condition_ref?.value || []}
              renderInput={(params) => <TextField {...params} />}
            />
          </div> */}

          {getUserResponse?.data?.role === ROLE.ADMIN && (
            <>
              <div className="customInputWrpr mt-3">
                <p className="mb-1">Tested By</p>
                <Autocomplete
                  name="tested_by_ref.value"
                  multiple
                  id="tested_by_ref.value"
                  options={EmployeeListPageResponse?.data || []}
                  className="w-100"
                  getOptionLabel={(option) => option?.first_name}
                  getOptionValue={(option) => option?._id} //
                  defaultValue={[EmployeeListPageResponse?.data[0] || []]}
                  filterSelectedOptions
                  size="small"
                  limitTags={2}
                  onChange={(event, newValue) => {
                    searchFormik.setFieldValue("tested_by_ref.value", newValue);
                  }}
                  onBlur={searchFormik.handleBlur}
                  value={searchFormik.values?.tested_by_ref?.value || []}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div>

              {/* <div className="customInputWrpr mt-3">
                <p className="mb-1">Received By</p>
                <Autocomplete
                  name="received_by_ref.value"
                  multiple
                  id="received_by_ref.value"
                  options={EmployeeListPageResponse?.data || []}
                  className="w-100"
                  getOptionLabel={(option) => option?.first_name}
                  getOptionValue={(option) => option?._id} //
                  defaultValue={[EmployeeListPageResponse?.data[0] || []]}
                  filterSelectedOptions
                  size="small"
                  limitTags={2}
                  onChange={(event, newValue) => {
                    searchFormik.setFieldValue(
                      "received_by_ref.value",
                      newValue
                    );
                  }}
                  onBlur={searchFormik.handleBlur}
                  value={searchFormik.values?.received_by_ref?.value || []}
                  renderInput={(params) => <TextField {...params} />}
                />
              </div> */}
            </>
          )}

          <div className="customInputWrpr mt-3 row">
            <p className="mb-1">Expiry Date</p>
            <div className="col-md-6">
              <CustomInput
                type="date"
                name="filters.rpt_expiry_date.value[0]"
                value={searchFormik.values.filters.rpt_expiry_date.value[0]}
                onChange={searchFormik.handleChange}
                onBlur={searchFormik.handleBlur}
                formik={searchFormik}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                type="date"
                name="filters.rpt_expiry_date.value[1]"
                value={searchFormik.values.filters.rpt_expiry_date.value[1]}
                onChange={searchFormik.handleChange}
                onBlur={searchFormik.handleBlur}
                formik={searchFormik}
              />
            </div>
          </div>

          <div className="customInputWrpr mt-3 row">
            <p className="mb-1">Received Date</p>
            <div className="col-md-6">
              <CustomInput
                type="date"
                name="filters.received_date.value[0]"
                value={searchFormik.values.filters.received_date.value[0]}
                onChange={searchFormik.handleChange}
                onBlur={searchFormik.handleBlur}
                formik={searchFormik}
              />
            </div>
            <div className="col-md-6">
              <CustomInput
                type="date"
                name="filters.received_date.value[1]"
                value={searchFormik.values.filters.received_date.value[1]}
                onChange={searchFormik.handleChange}
                onBlur={searchFormik.handleBlur}
                formik={searchFormik}
              />
            </div>
          </div>

          <div className="d-flex gap-3 justify-content-sb">
            <CustomButton type="submit" label="Filter" />
            <CustomButton
              btnType="secondary"
              label="Reset"
              //   onClick={handleFilterClose}
              onClick={() => {
                handleFilter();
                handleFilterClose();
              }}
            />
          </div>
        </div>
      </form>
    </div>
  );
}
