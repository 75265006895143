import { takeLatest, put, call } from "redux-saga/effects";
import EmployeeListTypes from "./EmployeesActionTypes";

import axios from "axios";
import {
  getEmployeeListResponse,
  postEmployeeResponse,
  putEmployeeResponse,
} from "./EmployeesActions";
import { UnautherizedResponse } from "../GetUser/GetUserActions";
import { axiosGet, axiosPost, axiosPut } from "../../Utils/axios";

export function* onGetEmployeeList({ payload }) {
  try {
    let response = yield call(() =>
      axiosPost("employee/index", payload).then((res) => res?.data)
    );

    yield put(getEmployeeListResponse(response));
  } catch (error) {
    yield put(UnautherizedResponse(error));
  }
}

export function* onPostEmployee({ payload }) {
  try {
    let response = yield call(() =>
      axiosPost("employee/new", payload).then((res) => res?.data)
    );
    yield put(postEmployeeResponse(response));
  } catch (error) {
    yield put(UnautherizedResponse(error));
  }
}

export function* onPutEmployee({ payload }) {
  try {
    let response = yield call(() =>
      axiosPut(`employee/${payload.id}`, payload).then((res) => res?.data)
    );
    yield put(putEmployeeResponse(response));
  } catch (error) {
    yield put(UnautherizedResponse(error));
  }
}

export function* EmployeeListWatcherSaga() {
  yield takeLatest(
    EmployeeListTypes.GET_EMPLOYEE_LIST_REQUEST,
    onGetEmployeeList
  );
  yield takeLatest(EmployeeListTypes.POST_EMPLOYEE_REQUEST, onPostEmployee);

  yield takeLatest(EmployeeListTypes.PUT_EMPLOYEE_REQUEST, onPutEmployee);
}
