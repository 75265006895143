import { takeLatest, put, call } from "redux-saga/effects";
import ConditionListTypes from "./ConditonActionTypes";
import axios from "axios";
import {
  getConditionByIdResponse,
  getConditionListResponse,
  postConditionResponse,
  putConditionResponse,
} from "./ConditionActions";
import { UnautherizedResponse } from "../../GetUser/GetUserActions";
import { axiosGet, axiosPost, axiosPut } from "../../../Utils/axios";

export function* onGetConditionList({ payload }) {
  try {
    let response = yield call(() =>
      axiosPost("sample_condition/index", payload).then((res) => res?.data)
    );

    yield put(getConditionListResponse(response));
  } catch (error) {
    yield put(getConditionListResponse(error));
    yield put(UnautherizedResponse(error));
  }
}

export function* onPostCondition({ payload }) {
  try {
    let response = yield call(() =>
      axiosPost("sample_condition/new", payload).then((res) => res?.data)
    );
    yield put(postConditionResponse(response));
  } catch (error) {
    yield put(UnautherizedResponse(error));
  }
}

export function* onGetByIdCondition({ payload }) {
  try {
    let response = yield call(() =>
      axiosGet(`sample_condition/${payload}`, payload).then((res) => res?.data)
    );
    yield put(getConditionByIdResponse(response));
  } catch (error) {
    yield put(UnautherizedResponse(error));
  }
}

export function* onPutCondition({ payload }) {
  try {
    let response = yield call(() =>
      axiosPut(`sample_condition/${payload._id}`, payload).then(
        (res) => res?.data
      )
    );
    yield put(putConditionResponse(response));
  } catch (error) {
    yield put(UnautherizedResponse(error));
  }
}

export function* ConditionListWatcherSaga() {
  yield takeLatest(
    ConditionListTypes.GET_CONDITION_LIST_REQUEST,
    onGetConditionList
  );

  yield takeLatest(ConditionListTypes.POST_CONDITION_REQUEST, onPostCondition);
  yield takeLatest(
    ConditionListTypes.GET_CONDITION_BYID_REQUEST,
    onGetByIdCondition
  );

  yield takeLatest(ConditionListTypes.PUT_CONDITION_REQUEST, onPutCondition);
}
