import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

import toast from "react-hot-toast";
import { UnautherizedResponseClear } from "../Redux/GetUser/GetUserActions";
import { CircularProgress } from "@mui/material";

function ProtectedRouter() {
  const {
    getUserResponse: userData,
    getUserLoading,
    unautherizedResponse,
  } = useSelector((state) => state.user);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!unautherizedResponse) return;

    if (unautherizedResponse?.response?.data?.message) {
      if (
        unautherizedResponse?.response?.data?.message ===
        "Token Must be Provided"
      ) {
        // Do nothing if no token is provided
      } else if (
        unautherizedResponse?.response?.data?.message === "Unauthenticated"
      ) {
        localStorage.clear();
        alert(
          "You're logged in from different device or location. So you,ll be redirected to login page, Thank you!"
        );
        window.location.reload();
      } else if (
        unautherizedResponse?.response?.data?.message === "Session has expired"
      ) {
        toast.error("Session Expired!");

        localStorage.clear();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (
        unautherizedResponse?.response?.data?.message === "Signature failed"
      ) {
        toast.error("Signature failed!");

        localStorage.clear();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (
        unautherizedResponse?.response?.data?.message === "User is Inactive"
      ) {
        toast.error("Your Id is Inactive");

        localStorage.clear();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (
        unautherizedResponse?.response?.data?.message ===
        "Your account has been deleted"
      ) {
        toast.error(unautherizedResponse?.response?.data?.message);
        localStorage.clear();
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else if (
        unautherizedResponse?.response?.data?.message === "Invalid Token"
      ) {
        localStorage.clear();
        alert(
          "Invalid Token. So you,ll be redirected to login page, Thank you!"
        );
        window.location.reload();
      } else {
        toast.error(unautherizedResponse?.response?.data?.message);
      }
    } else {
      toast.error(unautherizedResponse?.message || "Something went wrong!");
    }
    dispatch(UnautherizedResponseClear());
  }, [unautherizedResponse]);

  if (getUserLoading && !userData) {
    return (
      <div style={{ textAlign: "center" }}>
        <CircularProgress size={25} />
      </div>
    );
  }
  return Boolean(userData) ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}

export default ProtectedRouter;
