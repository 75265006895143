import { takeLatest, put, call } from "redux-saga/effects";
import StatListTypes from "./StateActionTypes";
import { getStateListResponse } from "./StateActions";
import { UnautherizedResponse } from "../../GetUser/GetUserActions";
import { axiosGet } from "../../../Utils/axios";

export function* onGetStateList({ payload }) {
  try {
    let response = yield call(() =>
      axiosGet(`employee/get_state?country_name=${payload}`, payload).then(
        (res) => res?.data
      )
    );

    yield put(getStateListResponse(response));
  } catch (error) {
    yield put(UnautherizedResponse(error));
  }
}

export function* StateListWatcherSaga() {
  yield takeLatest(StatListTypes.GET_STATE_LIST_REQUEST, onGetStateList);
}
