export default {
  GET_SAMPLE_LIST_REQUEST: "GET_SAMPLE_LIST_REQUEST",
  GET_SAMPLE_LIST_RESPONSE: "GET_SAMPLE_LIST_RESPONSE",
  GET_SAMPLE_LIST_RESET: "GET_SAMPLE_LIST_RESET",

  POST_SAMPLE_REQUEST: "POST_SAMPLE_REQUEST",
  POST_SAMPLE_RESPONSE: "POST_SAMPLE_RESPONSE",
  POST_SAMPLE_RESPONSE_CLEAR: "POST_SAMPLE_RESPONSE_CLEAR",

  PUT_SAMPLE_REQUEST: "PUT_SAMPLE_REQUEST",
  PUT_SAMPLE_RESPONSE: "PUT_SAMPLE_RESPONSE",
  PUT_SAMPLE_RESPONSE_CLEAR: "PUT_SAMPLE_RESPONSE_CLEAR",

  GET_SAMPLE_STATUS_REQUEST: "GET_SAMPLE_STATUS_REQUEST",
  GET_SAMPLE_STATUS_RESPONSE: "GET_SAMPLE_STATUS_RESPONSE",
};
