import EmployeeListTypes from "./EmployeesActionTypes";

//-------employeelist GET------------------------
export const getEmployeeListRequest = (payload) => ({
  type: EmployeeListTypes.GET_EMPLOYEE_LIST_REQUEST,
  payload: payload,
});

export const getEmployeeListResponse = (payload) => ({
  type: EmployeeListTypes.GET_EMPLOYEE_LIST_RESPONSE,
  payload: payload,
});

//--------employee POST--------------------------
export const postEmployeeRequest = (payload) => ({
  type: EmployeeListTypes.POST_EMPLOYEE_REQUEST,
  payload: payload,
});

export const postEmployeeResponse = (payload) => ({
  type: EmployeeListTypes.POST_EMPLOYEE_RESPONSE,
  payload: payload,
});

export const postEmployeeResponseClear = () => ({
  type: EmployeeListTypes.POST_EMPLOYEE_RESPONSE_CLEAR,
});

//--------employee PUT--------------------------------
export const putEmployeeRequest = (payload) => ({
  type: EmployeeListTypes.PUT_EMPLOYEE_REQUEST,
  payload: payload,
});

export const putEmployeeResponse = (payload) => ({
  type: EmployeeListTypes.PUT_EMPLOYEE_RESPONSE,
  payload: payload,
});

export const putEmployeeResponseClear = () => ({
  type: EmployeeListTypes.PUT_EMPLOYEE_RESPONSE_CLEAR,
});
