import React from "react";
import "./AuthLayout.css";

const AuthLayout = (props) => {
  return (
    <div className="loginWrapper">
      <div className="lftMdWrpr">
        <div className="loginimg_wrpr">
          <div className="bck_img">
            <img src="/Assets/Images/lgnwrpr.png" alt="Login" />
          </div>
          <div className="blurred-background"></div>
          <div className="centered">
            <img src="/Assets/Images/react-logo.png" alt="Login" />
          </div>
        </div>
      </div>
      <div className="rtFrmWrpr">{props.children}</div>
    </div>
  );
};

export default AuthLayout;
