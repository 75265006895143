import React from "react";
import "./index.css";

export default function AvailableStatus({
  type,
  status,
  color = "",
  bgColor = "",
}) {
  const availClassName = type ? "available-status" : "unavailable-status";

  return (
    <p style={{ color, backgroundColor: bgColor }} className={availClassName}>
      {status}
    </p>
  );
}
