import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
// import { forgotPasswordResClear } from "../../redux/Actions/LoginActions";
import CustomInput from "../../Components/Atoms/CustomInput";
import {
  postForgotPasswordRequest,
  postForgotPasswordResponseClear,
} from "../../Redux/ForgotAndResetPassword/ForgotAndResetPasswordActions";
import AuthLayout from "../../Components/Layout/AuthLayout";
import CustomButton from "../../Components/Atoms/CustomButton";

const Forgotpassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { PostForgotPasswordLoading, PostForgotPasswordResponse } = useSelector(
    (state) => state.forgotAndResetPasswordReducer
  );

  const validationArray = Yup.object().shape({
    email: Yup.string().email().required("Required"),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      redirect_url: "/resetpassword",
    },
    validationSchema: validationArray,
    onSubmit: async (values) => {
      if (PostForgotPasswordLoading) return;
      dispatch(postForgotPasswordRequest(values));
    },
  });

  useEffect(() => {
    if (
      PostForgotPasswordResponse?.success &&
      PostForgotPasswordResponse?.message
    ) {
      toast.success(PostForgotPasswordResponse?.message);
      navigate(-1);
    } else if (PostForgotPasswordResponse?.response?.data) {
      toast.error(PostForgotPasswordResponse?.response?.data?.message);
    }

    return () => {
      dispatch(postForgotPasswordResponseClear());
    };
    return;
  }, [PostForgotPasswordResponse]);

  return (
    <AuthLayout showButton={true}>
      <div className="lgnFormContainer">
        <form onSubmit={formik.handleSubmit}>
          <div className="frmHdr text-center">
            <h5>Forgot Password</h5>
            <p className="mt-3">
              Oops! Seems like you forgot your password don't worry we'll help
              you reset your password
            </p>
          </div>
          <div className="">
            <div className="col-md-12">
              <div className="d-flex">
                <p className="mb-1">Email</p>
                <p className="erroStar">*</p>
              </div>
              <CustomInput
                placeholder="Enter email"
                type="email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                formik={formik}
              />
            </div>

            {/* <div className="col-md-12"> */}
            <CustomButton
              label={PostForgotPasswordLoading ? "loading.." : "Send Mail"}
              type="submit"
            />
            {/* </div> */}
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};
export default Forgotpassword;
