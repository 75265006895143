import CountryListTypes from "./CountryActionTypes";

const initialState = {
  CountryListLoading: false,
  CountryListResponse: null,
};

const CountryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case CountryListTypes.GET_COUNTRY_LIST_REQUEST:
      return {
        ...state,
        CountryListLoading: true,
      };

    case CountryListTypes.GET_COUNTRY_LIST_RESPONSE:
      return {
        ...state,
        CountryListLoading: false,
        CountryListResponse: action.payload,
      };

    default:
      return state;
  }
};

export default CountryListReducer;
