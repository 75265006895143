import { takeLatest, put, call } from "redux-saga/effects";
import CountryListTypes from "./CountryActionTypes";

import { getCountryListResponse } from "./CountryActions";
import { UnautherizedResponse } from "../../GetUser/GetUserActions";
import { axiosGet } from "../../../Utils/axios";

export function* onGetCountryList({ payload }) {
  try {
    let response = yield call(() =>
      axiosGet("employee/get_country", payload).then((res) => res?.data)
    );

    yield put(getCountryListResponse(response));
  } catch (error) {
    yield put(UnautherizedResponse(error));
  }
}

export function* CountryListWatcherSaga() {
  yield takeLatest(CountryListTypes.GET_COUNTRY_LIST_REQUEST, onGetCountryList);
}
