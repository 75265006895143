import React, { useEffect } from "react";
import CustomInput from "../../../Components/Atoms/CustomInput";
import CustomButton from "../../../Components/Atoms/CustomButton";
import CustomSelect from "../../../Components/Atoms/CustomSelect";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  postEmployeeRequest,
  postEmployeeResponseClear,
  putEmployeeRequest,
  putEmployeeResponseClear,
} from "../../../Redux/Employees/EmployeesActions";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { countryList, expression, role, stateList } from "../../../Constant";
import { getCountryListRequest } from "../../../Redux/CountryState/Country/CountryActions";
import { getStateListRequest } from "../../../Redux/CountryState/State/StateActions";
const INDIAN_MOBILE_REGEX = /^(\+?91|0)?[6789]\d{9}$/;

const AddEmployees = ({ handleClose, employeeData }) => {
  const dispatch = useDispatch();

  const { EmployeePostResponse, EmployeePutResponse, EmployeePostLoading } =
    useSelector((state) => state.employee);
  const { StatListResponse } = useSelector((state) => state.state);
  const { CountryListResponse } = useSelector((state) => state.country);

  const validationArray = Yup.object({
    first_name: Yup.string()
      .required("Required")
      .min(3, "Minimum of 3 characters")
      .max(30, "Maximum of 30 characters")
      .matches(expression.min_one_char, "First name should have a character")
      .matches(expression.space, "First name should not contain spaces"),
    last_name: Yup.string()
      .min(3, "Minimum of 3 characters")
      .max(30, "Maximum of 30 characters")
      .matches(expression.min_one_char, "Last name should have a character")
      .matches(expression.space, "Last name should not contain spaces"),
    phone_number: Yup.string()
      .matches(INDIAN_MOBILE_REGEX, "Invalid  mobile number")
      .required("Required"),
    address: Yup.string()
      .min(3, "Minimum of 3 characters")
      .max(200, "Maximum of 200 characters")
      .matches(expression.min_one_char, "Address should have a character")
      .matches(expression.space, "Address should not contain spaces"),

    email: Yup.string().email().required("Required"),
    role: Yup.string().required("Required"),

    city: Yup.string()
      .min(3, "Minimum of 3 characters")
      .max(30, "Maximum of 30 characters")
      .matches(expression.min_one_char, "City should have a character")
      .matches(expression.space, "City should not contain spaces"),
    zipcode: Yup.string()
      .min(3, "Minimum of 3 digits")
      .max(10, "Maximum of 10 digits")
      .matches(expression.zipcode, "Zip Code is not valid")
      .matches(expression.space, "Zipcode should not contain spaces"),
  });

  const formik = useFormik({
    initialValues: {
      first_name: "",
      email: "",
      last_name: "",
      phone_number: "",
      role: "",

      address_detail: {
        state: "",
        country: "",
        address: "",
        city: "",
        zipcode: "",
      },
    },
    validationSchema: validationArray,
    onSubmit: (values) => {
      if (employeeData) {
        dispatch(putEmployeeRequest({ ...values, id: employeeData._id }));
      } else {
        dispatch(postEmployeeRequest(values));
      }
    },
  });

  useEffect(() => {
    if (EmployeePostResponse?.success && EmployeePostResponse?.message) {
      toast.success(EmployeePostResponse?.message);
      formik.resetForm();
      handleClose();
    } else if (EmployeePostResponse?.response?.data) {
      toast.error(EmployeePostResponse?.response?.data?.message);
    }

    return () => {
      dispatch(postEmployeeResponseClear());
    };
    return;
  }, [EmployeePostResponse]);

  useEffect(() => {
    dispatch(getCountryListRequest());
  }, []);

  useEffect(() => {
    if (formik.values.address_detail.country) {
      dispatch(getStateListRequest(formik.values.address_detail.country));
    }
  }, [formik.values.address_detail?.country]);

  useEffect(() => {
    if (EmployeePutResponse?.success && EmployeePutResponse?.message) {
      formik.resetForm();
      handleClose();
    }

    return () => {
      dispatch(putEmployeeResponseClear());
    };
  }, [EmployeePutResponse]);

  useEffect(() => {
    if (employeeData) {
      formik.setFieldValue("first_name", employeeData?.first_name);
      formik.setFieldValue("last_name", employeeData?.last_name);
      formik.setFieldValue("email", employeeData?.email);
      formik.setFieldValue("phone_number", employeeData?.phone_number);

      formik.setFieldValue(
        "address_detail.state",
        employeeData.address_detail?.state
      );
      formik.setFieldValue(
        "address_detail.country",
        employeeData.address_detail?.country
      );
      formik.setFieldValue(
        "address_detail.address",
        employeeData.address_detail?.address
      );
      formik.setFieldValue(
        "address_detail.city",
        employeeData?.address_detail?.city
      );
      formik.setFieldValue(
        "address_detail.zipcode",
        employeeData?.address_detail?.zipcode
      );
      formik.setFieldValue("role", employeeData?.role);
    }
  }, [employeeData]);

  return (
    <>
      <div className="">
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex">
                <p className="mb-1">First Name</p>

                <p className="erroStar">*</p>
              </div>
              <CustomInput
                type="text"
                name="first_name"
                placeholder="Enter first name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.first_name}
                formik={formik}
              />
            </div>
            <div className="col-md-6">
              <p className="mb-1">Last Name</p>
              <CustomInput
                type="text"
                name="last_name"
                placeholder="Enter last name"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
              />
            </div>{" "}
            <div className="col-md-6">
              <div className="d-flex">
                <p className="mb-1">Phone Number</p>

                <p className="erroStar">*</p>
              </div>
              <CustomInput
                type="text"
                name="phone_number"
                placeholder="Enter phone number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone_number}
                formik={formik}
              />
            </div>{" "}
            <div className="col-md-6">
              <div className="d-flex">
                <p className="mb-1">Email Address</p>
                <p className="erroStar">*</p>
              </div>
              <CustomInput
                type="text"
                name="email"
                placeholder="Enter email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                formik={formik}
              />
            </div>{" "}
            <div className="col-md-6">
              <p className="mb-1">Address</p>
              <CustomInput
                type="text"
                name="address_detail.address"
                placeholder="Enter address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address_detail.address}
                formik={formik}
              />
            </div>
            <div className="col-md-6">
              <p className="mb-1">Country</p>
              <CustomSelect
                type="text"
                name="address_detail.country"
                placeholder="Enter country"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address_detail.country}
                formik={formik}
                data={CountryListResponse?.data}
              />
            </div>
            <div className="col-md-6">
              <p className="mb-1">State</p>

              <CustomSelect
                type="text"
                name="address_detail.state"
                placeholder="Enter state"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address_detail.state}
                formik={formik}
                data={StatListResponse?.data}
              />
            </div>
            <div className="col-md-6">
              <p className="mb-1">City</p>
              <CustomInput
                type="text"
                name="address_detail.city"
                placeholder="Enter city"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address_detail.city}
                formik={formik}
              />
            </div>{" "}
            <div className="col-md-6">
              <p className="mb-1">Zipcode</p>
              <CustomInput
                type="text"
                name="address_detail.zipcode"
                placeholder="Enter zipcode"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address_detail.zipcode}
                formik={formik}
              />
            </div>
            <div className="col-md-6">
              <div className="d-flex">
                <p className="mb-1">Role</p>
                <p className="erroStar">*</p>
              </div>
              <CustomSelect
                type="text"
                name="role"
                placeholder="SELECT ROLE"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.role}
                formik={formik}
                data={role}
                errorMsg={formik.touched.role && formik.errors?.role}
              />
            </div>
            <div className="btnActnBar">
              <CustomButton
                label={"Cancel"}
                btnType="secondary"
                onClick={() => handleClose()}
              />
              <CustomButton
                label={employeeData ? "Update Employee" : "Add Employee"}
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddEmployees;
