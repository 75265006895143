import React from "react";
import "./index.css";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Button, Pagination, Stack } from "@mui/material";
import DropdownMenu from "../../Atoms/DropdownMenu";

const TablePagination = ({
  result,
  formik,
  action: submitAction,
  children,
  count,
  setPage,
  page,
}) => {
  const numberOfPages = Math.ceil(count / formik?.values?.filters?.limit) || 1;
  const handleListPerPage = (value) => {
    formik.setFieldValue("limit", value);
    setPage(1);

    const currentPage = formik?.values.page;

    const nextPageLength = Math.ceil(result?.total_count / value);

    if (currentPage >= nextPageLength) {
      // formik.setFieldValue("page", nextPageLength);
    }

    return submitAction({
      ...formik?.values,
      limit: value,
      page: currentPage >= nextPageLength ? nextPageLength : currentPage,
    });
  };

  const handlePagination = (event, value) => {
    // formik.setFieldValue("page", value);
    setPage(value);
    return submitAction({ ...formik?.values, page: value });
  };

  return (
    <div>
      {children}

      {result?.total_count ? (
        <div className="Pagination">
          <div className="pagi_list">
            <DropdownMenu
              menuList={[
                { label: "10", onClick: () => handleListPerPage(10) },
                { label: "25", onClick: () => handleListPerPage(25) },
                { label: "50", onClick: () => handleListPerPage(50) },
                {
                  label: "100",
                  onClick: () => handleListPerPage(100),
                },
              ]}
            >
              <Button variant="outlined" color="secondary">
                <span className="text-start text-capitalize">
                  {formik?.values.limit}
                </span>
                <KeyboardArrowDown className="ms-2" />
              </Button>
            </DropdownMenu>
            <p>Per Page</p>
          </div>
          <Pagination
            variant="outlined"
            shape="rounded"
            count={
              result?.total_count
                ? Math.ceil(result?.total_count / formik?.values.limit)
                : 0 || 0
            }
            color="primary"
            onChange={handlePagination}
            defaultValue={page}
            page={page}
          />

          {/* <div className="PaginationBtns">
            <button
              className="PagPrev"
              onClick={previousPage}
              disabled={formik?.values?.filters?.page === 1}
            ></button>
            <button
              className="PagNext"
              onClick={nextPage}
              disabled={formik?.values?.filters?.page >= numberOfPages}
            ></button>
          </div> */}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TablePagination;
