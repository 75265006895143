import React from "react";
import CustomButton from "../../Atoms/CustomButton";

const Tab = ({ tabs, selectedTab, handleTabChange }) => {
  return (
    <div className="reqTabHead">
      {tabs?.map((tab) => (
        <CustomButton
          key={tab.value}
          label={tab.label}
          size="small"
          btnType={selectedTab === tab.value ? "primary" : "terinary"}
          onClick={() => handleTabChange(tab.value)}
        />
      ))}
    </div>
  );
};

export default Tab;
