export default {
  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_RESPONSE: "GET_USER_RESPONSE",
  GET_USER_RESPONSE_CLEAR: "GET_USER_RESPONSE_CLEAR",

  SET_HEADER_SEARCH_INPUT: "SET_HEADER_SEARCH_INPUT",
  GET_HEADER_SEARCH_INPUT: "GET_HEADER_SEARCH_INPUT",

  UNAUTHERIZED_RESPONSE: "UNAUTHERIZED_RESPONSE",
  UNAUTHERIZED_RESPONSE_CLEAR: "UNAUTHERIZED_RESPONSE_CLEAR",

  GET_USER_BY_ID_REQUEST: "GET_USER_BY_ID_REQUEST",
  GET_USER_BY_ID_RESPONSE: "GET_USER_BY_ID_RESPONSE",
  GET_USER_BY_ID_RESPONSE_CLEAR: "GET_USER_BY_ID_RESPONSE_CLEAR",
};
