import UserTypes from "./GetUserActionTypes";

const initialState = {
  getUserLoading: null,
  getUserResponse: null,

  unautherizedResponse: null,

  getUserById: null,
  getUserByIdLoading: null,
  getUserByIdResponse: null,

  searchInput: "",
};

const GetUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserTypes.GET_USER_REQUEST:
      return {
        ...state,
        getUserLoading: true,
      };

    case UserTypes.GET_USER_RESPONSE:
      return {
        ...state,
        getUserLoading: false,
        getUserResponse: action.payload,
      };

    case UserTypes.GET_USER_RESPONSE_CLEAR:
      return {
        ...state,
        getUserResponse: null,
      };

    case UserTypes.SET_HEADER_SEARCH_INPUT:
      return {
        ...state,
        searchInput: action.payload,
      };

    case UserTypes.GET_USER_BY_ID_REQUEST:
      return {
        ...state,
        getUserByIdLoading: true,
      };

    case UserTypes.GET_USER_BY_ID_RESPONSE:
      return {
        ...state,
        getUserByIdLoading: false,
        getUserById: action.payload.data,
        getUserByIdResponse: action.payload,
      };

    case UserTypes.GET_USER_BY_ID_RESPONSE_CLEAR:
      return {
        ...state,
        getUserByIdResponse: null,
      };
    case UserTypes.UNAUTHERIZED_RESPONSE:
      return {
        ...state,
        unautherizedResponse: action.payload,
      };

    case UserTypes.UNAUTHERIZED_RESPONSE_CLEAR:
      return {
        ...state,
        unautherizedResponse: null,
      };

    default:
      return state;
  }
};

export default GetUserReducer;
