import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../Views/Login";
import Layout from "../Components/Organisms/Layout";
import Dashboard from "../Views/Dashboard";
import Employees from "../Views/Employees";
import Samples from "../Views/Samples";
import SettingsCategory from "../Views/Settings";
import ProtectedRouter from "./ProductedRouter";
import Forgotpassword from "../Views/Login/ForgotPassword";
import ResetPassword from "../Views/Login/ResetPassword";

const Router = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path="/login" element={<Login />} />
            <Route path="/forgotpassword" element={<Forgotpassword />} />
            <Route path="/resetpassword" element={<ResetPassword />} />
          </Route>
          <Route element={<ProtectedRouter />}>
            <Route element={<Layout />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/employees" element={<Employees />} />
              <Route path="/samples" element={<Samples />} />
              <Route path="/settingscategory" element={<SettingsCategory />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default Router;
