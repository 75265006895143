import { takeLatest, put, call } from "redux-saga/effects";
import LoginTypes from "./LoginActionTypes";
import { postLoginResponse } from "./LoginActions";
import { UnautherizedResponse } from "../GetUser/GetUserActions";
import { axiosPost, setAuthToken } from "../../Utils/axios";

export function* onPostLoginSaga({ payload }) {
  try {
    let response = yield call(() =>
      axiosPost("auth/login", payload).then((res) => res?.data)
    );

    if (response?.success) {
      localStorage.setItem("token", response?.data.token);
      setAuthToken(response?.data.token);
    }

    yield put(postLoginResponse(response));
  } catch (error) {
    yield put(postLoginResponse(error));
    // yield put(UnautherizedResponse(error));
  }
}

//WATCHER
export function* LoginWatcherSaga() {
  yield takeLatest(LoginTypes.POST_LOGIN_REQUEST, onPostLoginSaga);
}
