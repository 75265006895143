import ConditionListTypes from "./ConditonActionTypes";

//------conditionlist GET------------------------
export const getConditionListRequest = (payload) => ({
  type: ConditionListTypes.GET_CONDITION_LIST_REQUEST,
  payload: payload,
});

export const getConditionListResponse = (payload) => ({
  type: ConditionListTypes.GET_CONDITION_LIST_RESPONSE,
  payload: payload,
});

//--------condition POST--------------------------
export const postConditionRequest = (payload) => ({
  type: ConditionListTypes.POST_CONDITION_REQUEST,
  payload: payload,
});

export const postConditionResponse = (payload) => ({
  type: ConditionListTypes.POST_CONDITION_RESPONSE,
  payload: payload,
});

export const postConditionResponseClear = () => ({
  type: ConditionListTypes.POST_CONDITION_RESPONSE_CLEAR,
});

//--------condition PUT--------------------------------
export const putConditionRequest = (payload) => ({
  type: ConditionListTypes.PUT_CONDITION_REQUEST,
  payload: payload,
});

export const putConditionResponse = (payload) => ({
  type: ConditionListTypes.PUT_CONDITION_RESPONSE,
  payload: payload,
});

export const putConditionResponseClear = () => ({
  type: ConditionListTypes.PUT_CONDITION_RESPONSE_CLEAR,
});

//
export const getConditonByIdRequest = (payload) => ({
  type: ConditionListTypes.GET_CONDITION_BYID_REQUEST,
  payload: payload,
});

export const getConditionByIdResponse = (payload) => ({
  type: ConditionListTypes.GET_CONDITION_BYID_RESPONSE,
  payload: payload,
});
export const getConditionByIdResponseClear = () => ({
  type: ConditionListTypes.GET_CONDITION_BYID_RESCLEAR,
});
