import { Button } from "@mui/material";
import "./index.css";
import { Search } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { getSampleListRequest } from "../../../Redux/Sample/SampleActions";
import { useDispatch } from "react-redux";

const SearchInput = ({
  name,
  value,
  onChange,
  disabled,
  handleSubmit,
  onSubmit,
  searchFormik,
  searchSample,
}) => {
  const dispatch = useDispatch();

  return (
    <form className="SearchBar" onSubmit={handleSubmit}>
      <input
        placeholder="Search..."
        name={name}
        value={value}
        onChange={onChange}
      />
      {searchFormik?.values?.filters?.search?.value && (
        <Button
          className="close_btn"
          onClick={() => {
            searchFormik.resetForm();
            dispatch(getSampleListRequest(searchSample));
          }}
        >
          <CloseIcon />
        </Button>
      )}

      <button type="submit" onClick={onSubmit} className="search_btn">
        <Search />
      </button>
    </form>
  );
};

export default SearchInput;
