import EmployeeListTypes from "./EmployeesActionTypes";

const initialState = {
  EmployeeListPageLoading: false,
  EmployeeListPageResponse: null,
  EmployeePostLoading: false,
  EmployeePostResponse: null,
  EmployeePutLoading: false,
  EmployeePutResponse: null,
};

const EmployeeListReducer = (state = initialState, action) => {
  switch (action.type) {
    case EmployeeListTypes.GET_EMPLOYEE_LIST_REQUEST:
      return {
        ...state,
        EmployeeListPageLoading: true,
      };

    case EmployeeListTypes.GET_EMPLOYEE_LIST_RESPONSE:
      return {
        ...state,
        EmployeeListPageLoading: false,
        EmployeeListPageResponse: action.payload.data,
      };

    case EmployeeListTypes.POST_EMPLOYEE_REQUEST:
      return {
        ...state,
        EmployeePostLoading: true,
      };

    case EmployeeListTypes.POST_EMPLOYEE_RESPONSE:
      return {
        ...state,
        EmployeePostLoading: false,
        EmployeePostResponse: action.payload,
      };

    case EmployeeListTypes.POST_EMPLOYEE_RESPONSE_CLEAR:
      return {
        ...state,
        EmployeePostResponse: null,
      };

    case EmployeeListTypes.PUT_EMPLOYEE_REQUEST:
      return {
        ...state,
        EmployeePutLoading: true,
      };

    case EmployeeListTypes.PUT_EMPLOYEE_RESPONSE:
      return {
        ...state,
        EmployeePutLoading: false,
        EmployeePutResponse: action.payload,
      };

    case EmployeeListTypes.PUT_EMPLOYEE_RESPONSE_CLEAR:
      return {
        ...state,
        EmployeePutResponse: null,
      };

    default:
      return state;
  }
};

export default EmployeeListReducer;
