import { KeyboardArrowDown, NotificationAdd } from "@mui/icons-material";
import {
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";

import moment from "moment";
import * as yup from "yup";

import axios from "axios";
import { toast } from "react-hot-toast";
import CustomSelect from "../../Atoms/CustomSelect";
import CustomInput from "../../Atoms/CustomInput";

const ExportData = ({
  editPdf = {},
  exportLinks = [],
  customDates = true,
  cdConfig = {},
  standalone = false,
  standaloneLabel,
  standaloneUrl,
  standaloneFilename,
  standaloneExtension,
  extraFilterFields,
  initialValues,
  extraValidation,
}) => {
  const formik = useFormik({
    initialValues: initialValues || {
      from_date: "",
      to_date: "",
      name: "",
    },
    validationSchema:
      extraValidation ||
      yup.object().shape({
        from_date: yup
          .date()
          .max(
            moment().startOf("day").toISOString(),
            "Date can't later than today!"
          )
          .required("Required"),
        to_date: yup
          .date()
          .min(
            yup.ref("from_date"),
            "To date cannot be earlier than start date"
          )
          .max(
            moment().startOf("day").toISOString(),
            "Date can't later than today!"
          )
          .required("Required"),
      }),
    onSubmit: (values) => {
      values.name = `${cdConfig.name} - ${
        values.from_date === values.to_date
          ? values.from_date
          : values.from_date + " - " + values.to_date
      }`;

      handleDownloadFile({
        url: cdConfig.url ? cdConfig.url : "/report-export",
        filename: `${values.name}.${cdConfig.extension}`,
        payload: {
          ...values,
          type: cdConfig.type,
        },
        method: "POST",
      });

      formik.resetForm();

      return handleClose();
    },
  });

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (standalone) {
      return handleDownloadFile({
        url: standaloneUrl,
        filename: `${standaloneFilename} - ${new Date()
          .toISOString()
          .slice(0, 10)}.${standaloneExtension}`,
        method: "GET",
      });
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    return setAnchorEl(null);
  };

  const handleClicks = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloses = () => {
    setAnchorEl(null);
  };

  const [estDialog, setEstDialog] = useState({
    mount: false,
    data: null,
  });

  const handleOpenEstDialog = () =>
    setEstDialog({
      mount: true,
    });
  const handleCloseEstDialog = () => setEstDialog({ mount: false, data: null });

  const open = Boolean(anchorEl);
  const id = open ? "export-popover" : undefined;

  const handleDirectExport = ({ name, extension, type, url }) => {
    handleDownloadFile({
      url: url,
      filename: `${name} - ${new Date()
        .toISOString()
        .slice(0, 10)} .${extension}`,
      payload: type ? { from_date: "", to_date: "", type: type } : "",
      method: type ? "POST" : "GET",
    });
    return handleClose();
  };

  return (
    <div className="export_data_container">
      <div className="btnWrpr">
        <Button
          aria-describedby={id}
          variant="outlined"
          color="secondary"
          onClick={handleClick}
        >
          <span className="text-start" style={{ width: "95px" }}>
            {standalone == false && cdConfig?.type == "closingstock"
              ? standaloneLabel || "Closing Stock"
              : standalone == false && cdConfig?.type == "inventorycount"
              ? standaloneLabel || "Inventory"
              : standalone == false && cdConfig?.type == "vndrslist"
              ? standaloneLabel || "Vendors List"
              : standalone == false && cdConfig?.name == "Price benefit"
              ? standaloneLabel || "Price Benefit"
              : "Export"}
          </span>{" "}
          {!standalone && <KeyboardArrowDown className="ms-auto ms-md-0" />}
        </Button>

        {!standalone && (
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={() => handleClose()}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            PaperProps={{ sx: { minWidth: 240 } }}
          >
            {editPdf === true && (
              <MenuItem
                className="py-2 px-2"
                onClick={() => handleOpenEstDialog()}
              >
                Vendor Master List
              </MenuItem>
            )}

            {exportLinks?.map((link) => (
              <MenuItem
                className="py-2 px-2"
                onClick={() =>
                  handleDirectExport({
                    label: link.label,
                    name: link.name,
                    extension: link.extension,
                    type: link.type,
                    url: link.url ? link.url : "/report-export",
                  })
                }
              >
                {link.label}
              </MenuItem>
            ))}

            {!!exportLinks?.length ? <Divider className="my-0" /> : ""}
            {customDates ? (
              <div>
                <form
                  onSubmit={formik.handleSubmit}
                  className="d-flex flex-column p-2"
                >
                  <p className="text-muted">Custom Dates</p>

                  <CustomInput
                    label="From Date"
                    type="date"
                    name="from_date"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.from_date}
                    formik={formik}
                  />
                  <CustomInput
                    label="To Date"
                    type="date"
                    name="to_date"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.to_date}
                    formik={formik}
                  />

                  {extraFilterFields &&
                    extraFilterFields?.map((field) => (
                      <>
                        {field.inputType === "select" ? (
                          <CustomSelect
                            name={field.name}
                            label={field.label}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values[field.name]}
                            options={field.options}
                            formik={formik}
                          />
                        ) : field.inputType === "input" ? (
                          <CustomSelect
                            name={field.name}
                            label={field.label}
                            type={field.type}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values[field.name]}
                            formik={formik}
                          />
                        ) : field.inputType == "radio" ? (
                          <div className="customInputWrpr">
                            <FormControl className="d-flex flex-column mb-2">
                              <FormLabel>{field.label}: </FormLabel>
                              <RadioGroup
                                name={field.name}
                                value={formik.values[field.name]}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              >
                                {field?.list?.map(({ value, label }) => (
                                  <FormControlLabel
                                    value={value}
                                    control={<Radio size="small" />}
                                    label={label}
                                  />
                                ))}
                              </RadioGroup>
                            </FormControl>
                            <p className="errMsg">
                              {formik.errors[field.name]}
                            </p>
                          </div>
                        ) : field.inputType == "checkboxs" ? (
                          ""
                        ) : (
                          ""
                        )}
                      </>
                    ))}

                  <Button variant="contained" fullWidth type="submit">
                    Submit
                  </Button>
                </form>
              </div>
            ) : (
              ""
            )}
          </Popover>
        )}
      </div>
    </div>
  );
};

export default ExportData;

export const handleDownloadFile = ({ url, filename, payload, method }) => {
  const notify = toast.loading("Exporting Document..");

  return axios
    .request({ url: url, method: method, responseType: "blob", data: payload })
    .then((res) => {
      if (res.data && typeof res.data == "object") {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        toast.success("Document Exported!", { id: notify });
      } else {
        toast.error("Failed to export Document!", { id: notify });
      }
    })
    .catch((res) => {
      if (res?.response?.status === 400) {
        toast.error("No records found!", { id: notify });
      } else {
        toast.error("Failed to export Document!", { id: notify });
      }
    });
};
