import React, { useEffect, useState } from "react";

import CustomButton from "../../Components/Atoms/CustomButton";
import CustomInput from "../../Components/Atoms/CustomInput";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  postSampleRequest,
  postSampleResponseClear,
  putSampleRequest,
  putSampleResponseClear,
} from "../../Redux/Sample/SampleActions";

import CustomSelect from "../../Components/Atoms/CustomSelect";
import { expression, PURPOSE_OF_TESTING_DATA } from "../../Constant";
import moment from "moment";
import toast from "react-hot-toast";

const SampleForm = ({ sampleData, handleClose = () => {}, type, sample }) => {
  const dispatch = useDispatch();
  const {
    SamplePostResponse,
    SamplePostLoading,

    SamplePutResponse,
  } = useSelector((state) => state.sample);

  const { EmployeeListPageResponse } = useSelector((state) => state.employee);
  const { ConditionListPageResponse } = useSelector((state) => state.condition);
  const { CategoryListPageResponse } = useSelector((state) => state.category);

  const validationArray = Yup.object({
    applicant_name: Yup.string()
      .required("Required")
      .min(3, "Minimum of 3 characters")
      .max(30, "Maximum of 30 characters")
      .matches(expression.min_one_char, "Name should have a character")
      .matches(expression.space, "Name should not contain spaces"),
    brand_name: Yup.string()
      .required("Required")
      .min(2, "Minimum of 2 characters")
      .max(30, "Maximum of 30 characters")
      .matches(expression.min_one_char, "Name should have a character"),
    // .matches(expression.space, "Name should not contain spaces"),

    model_no: Yup.string()
      .required("Required")
      .nullable()
      .min(3, "Minimum of 3 digits")
      .max(55, "Maximum of 55 digits")
      .matches(expression.space, "Model Number should not contain spaces"),

    received_date: Yup.string()
      .test(
        "future-date",
        "Received date cannot be in the future",
        function (value) {
          const receivedDate = moment(value, "YYYY-MM-DD");
          const currentDate = moment();
          return receivedDate.isSameOrBefore(currentDate);
        }
      )
      .required("Required"),
    no_of_samples_received: Yup.number()
      .typeError("Only numbers are allowed")
      .required("Required")
      .nullable()
      .min(1, "Minimum of 1 digits")
      .max(999999999, "Maximum of 9 digits"),
    sample_condition_ref: Yup.string().required("Required"),
    received_by_ref: Yup.string().required("Required"),
    product_category_ref: Yup.string().required("Required").nullable(),
    purpose_of_testing: Yup.string().required("Required").nullable(),
  });

  const formik = useFormik({
    initialValues: {
      received_by_ref: "",
      received_date: new Date().toISOString().split("T")[0],
      product_category_ref: "",
      applicant_name: "",
      brand_name: "",
      model_no: "",
      sample_condition_ref: "",
      no_of_samples_received: "",
      purpose_of_testing: "",
      standard: "",
    },
    validationSchema: validationArray,
    onSubmit: (values) => {
      values.no_of_samples_received = parseInt(values.no_of_samples_received);

      if (sampleData) {
        dispatch(putSampleRequest({ ...values, id: sampleData?._id }));
      } else {
        dispatch(postSampleRequest(values));
      }
    },
  });

  useEffect(() => {
    if (sampleData) {
      let formattedReceivedDate = null;
      if (sampleData?.received_date) {
        const receivedDate = new Date(sampleData?.received_date);
        formattedReceivedDate = receivedDate.toISOString().split("T")[0];
      }
      formik.setFieldValue("received_date", formattedReceivedDate);

      formik.setFieldValue("received_by_ref", sampleData?.received_by_ref?._id);
      formik.setFieldValue(
        "product_category_ref",
        sampleData?.product_category_ref?._id
      );
      formik.setFieldValue("applicant_name", sampleData?.applicant_name);
      formik.setFieldValue("brand_name", sampleData?.brand_name);

      formik.setFieldValue("model_no", sampleData?.model_no);
      formik.setFieldValue(
        "sample_condition_ref",
        sampleData?.sample_condition_ref?._id
      );
      formik.setFieldValue(
        "no_of_samples_received",
        sampleData?.no_of_samples_received
      );
      formik.setFieldValue(
        "purpose_of_testing",
        sampleData?.purpose_of_testing
      );
      formik.setFieldValue("standard", sampleData?.standard);
    }
  }, [sampleData]);

  useEffect(() => {
    if (SamplePutResponse?.success && SamplePutResponse?.message) {
      formik.resetForm();
      handleClose();
    }

    return () => {
      dispatch(putSampleResponseClear());
    };
  }, [SamplePutResponse]);

  useEffect(() => {
    if (SamplePostResponse?.success && SamplePostResponse?.message) {
      toast.success(SamplePostResponse?.message);
      formik.resetForm();
    } else if (SamplePostResponse?.response?.data) {
      toast.error(SamplePostResponse?.response?.data?.message);
    }

    return () => {
      dispatch(postSampleResponseClear());
    };
  }, [SamplePostResponse]);

  return (
    <>
      <div className="dashboard_container">
        <div className="">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-4">
                <div className="d-flex">
                  <p className="mb-1">Sample Received Date</p>
                  <p className="erroStar">*</p>
                </div>
                <CustomInput
                  type="date"
                  name="received_date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.received_date}
                  formik={formik}
                />
              </div>
              <div className="col-md-4">
                <div className="d-flex">
                  <p className="mb-1">Applicant Name</p>
                  <p className="erroStar">*</p>
                </div>
                <CustomInput
                  type="text"
                  name="applicant_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.applicant_name}
                  formik={formik}
                  placeholder="Enter applicant name"
                />
              </div>
              <div className="col-md-4">
                <div className="d-flex">
                  <p className="mb-1">Brand Name</p>
                  <p className="erroStar">*</p>
                </div>
                <CustomInput
                  type="text"
                  name="brand_name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.brand_name}
                  formik={formik}
                  placeholder="Enter brand name"
                />
              </div>
              <div className="col-md-4">
                <div className="d-flex">
                  <p className="mb-1">Product Category</p>
                  <p className="erroStar">*</p>
                </div>
                <CustomSelect
                  type="text"
                  name="product_category_ref"
                  value={formik.values.product_category_ref}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formik={formik}
                  placeholder="SELECT CATEGORY"
                  errorMsg={
                    formik.touched.product_category_ref &&
                    formik.errors?.product_category_ref
                  }
                  data={CategoryListPageResponse?.data}
                  setValue="_id"
                  setOption="name"
                />
              </div>{" "}
              <div className="col-md-4">
                <div className="d-flex">
                  <p className="mb-1">Model No</p>
                  <p className="erroStar">*</p>
                </div>
                <CustomInput
                  name="model_no"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.model_no}
                  formik={formik}
                  placeholder="Enter model No."
                />
              </div>{" "}
              <div className="col-md-4">
                <div className="d-flex">
                  <p className="mb-1">No. Of Samples Received</p>
                  <p className="erroStar">*</p>
                </div>
                <CustomInput
                  type="text"
                  name="no_of_samples_received"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.no_of_samples_received}
                  formik={formik}
                  placeholder="Enter no. of samples"
                />
              </div>{" "}
              <div className="col-md-4">
                <div className="d-flex">
                  <p className="mb-1">Sample Condition</p>
                  <p className="erroStar">*</p>
                </div>
                <CustomSelect
                  type="text"
                  name="sample_condition_ref"
                  value={formik.values.sample_condition_ref}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formik={formik}
                  placeholder="SELECT CONDITION"
                  errorMsg={
                    formik.touched.sample_condition_ref &&
                    formik.errors?.sample_condition_ref
                  }
                  data={ConditionListPageResponse?.data}
                  setValue="_id"
                />
              </div>{" "}
              <div className="col-md-4">
                <div className="d-flex">
                  <p className="mb-1">Received By</p>
                  <p className="erroStar">*</p>
                </div>
                <CustomSelect
                  type="text"
                  name="received_by_ref"
                  value={formik.values.received_by_ref}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  formik={formik}
                  placeholder="SELECT EMPLOYEE"
                  setValue="_id"
                  setOption={"first_name"}
                  data={EmployeeListPageResponse?.data}
                  errorMsg={
                    formik.touched.received_by_ref &&
                    formik.errors?.received_by_ref
                  }
                />
              </div>{" "}
              <div className="col-md-4">
                <div className="d-flex">
                  <p className="mb-1">Purpose of Testing</p>
                  <p className="erroStar">*</p>
                </div>
                <CustomSelect
                  type="text"
                  name="purpose_of_testing"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.purpose_of_testing}
                  formik={formik}
                  placeholder="SELECT PURPOSE OF TESTING"
                  data={PURPOSE_OF_TESTING_DATA}
                  errorMsg={
                    formik.touched.purpose_of_testing &&
                    formik.errors?.purpose_of_testing
                  }
                />
              </div>
              <div className="col-md-4">
                <p className="mb-1">Standard</p>
                <CustomInput
                  type="text"
                  name="standard"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.standard}
                  formik={formik}
                  placeholder="Enter standard"
                />
              </div>
              <div className="btnActnBar">
                <CustomButton
                  label={type === "popup_edit" ? "Cancel" : "Reset"}
                  btnType="secondary"
                  onClick={() => {
                    handleClose();
                    formik.resetForm();
                  }}
                />
                <CustomButton
                  label={sampleData ? "Update" : "Add"}
                  type="submit"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default SampleForm;
