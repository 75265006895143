import React, { useEffect } from "react";
import CustomInput from "../../../Components/Atoms/CustomInput";
import CustomButton from "../../../Components/Atoms/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  getConditionByIdResponseClear,
  getConditonByIdRequest,
  postConditionRequest,
  postConditionResponse,
  postConditionResponseClear,
  putConditionRequest,
} from "../../../Redux/Settings/Condition/ConditionActions";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { expression } from "../../../Constant";

const ConditionForm = ({
  handleClose,
  categoryId,
  actionType,
  selectedTab,
}) => {
  const dispatch = useDispatch();
  const {
    ConditionPostResponse,
    ConditionGetByIdLoading,
    ConditionGetByIdResponse,
  } = useSelector((state) => state.condition);

  const validationArray = Yup.object({
    name: Yup.string()
      .required("Required")
      .min(3, "Minimum of 3 characters")
      .max(50, "Maximum of 50 characters")
      .matches(expression.min_one_char, "Condition should have a character")
      .matches(expression.space, "Condition should not contain spaces"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationArray,
    onSubmit: (values) => {
      if (actionType === "new") {
        dispatch(postConditionRequest(values));
      } else {
        dispatch(putConditionRequest(values));
      }
    },
  });

  useEffect(() => {
    if (ConditionPostResponse?.success && ConditionPostResponse?.message) {
      toast.success(ConditionPostResponse?.message);
      formik.resetForm();
      handleClose();
    } else if (ConditionPostResponse?.response?.data) {
      toast.error(ConditionPostResponse?.response?.data?.message);
    }

    return () => {
      dispatch(postConditionResponseClear());
    };
  }, [ConditionPostResponse]);

  useEffect(() => {
    if (actionType === "edit") {
      dispatch(getConditonByIdRequest(categoryId));
    }
    return () => {
      dispatch(getConditionByIdResponseClear());
    };
  }, [actionType]);

  useEffect(() => {
    if (actionType === "edit" && ConditionGetByIdResponse?.data) {
      formik.setFieldValue("_id", ConditionGetByIdResponse?.data?._id);
      formik.setFieldValue("name", ConditionGetByIdResponse?.data?.name);
    }
  }, [ConditionGetByIdResponse, ConditionGetByIdLoading]);
  return (
    <>
      <div className="">
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex">
                <p className="mb-1">Condition Name</p>
                <p className="erroStar">*</p>
              </div>
              <CustomInput
                type="text"
                name="name"
                placeholder="Enter condition"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
              />
            </div>

            <div className="btnActnBar">
              <CustomButton
                label={"Cancel"}
                btnType="secondary"
                onClick={() => handleClose()}
              />
              <CustomButton
                label={
                  selectedTab === "condition" && actionType === "edit"
                    ? "Update"
                    : selectedTab === "condition" && actionType === "edit"
                    ? "Update"
                    : selectedTab === "condition"
                    ? "Add"
                    : "Add"
                }
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ConditionForm;
