import * as React from "react";
import "./index.css";
import Dialog from "@mui/material/Dialog";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

export default function CustomDialog({
  open,
  maxWidth = "sm",
  fullWidth = true,
  handleClose,
  children,
  title,
  ...rest
}) {
  return (
    <Dialog
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      open={open}
      onClose={handleClose}
      {...rest}
    >
      <div className="dlghrde_prnt">
        <div className="dlg_hrdr">
          <p>{title}</p>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
      </div>

      <div className="pop_child ">{children}</div>
    </Dialog>
  );
}
