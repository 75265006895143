import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Popover } from "@mui/material";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
    className={props.className}
  />
))(({ theme, dropdownWidth }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: dropdownWidth,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function DropdownMenu({
  MenuClassName,
  containerClasses,
  menuList,
  children,
  dropContent,
  popoverEl,
  popoverOpen,
  handlePopoverClose,
  inputWidth,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  const [dropdownWidth, setDropdownWidth] = React.useState(180);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    const inputWidth = event.currentTarget.offsetWidth;
    setDropdownWidth(inputWidth);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <div
        className={containerClasses}
        id={id}
        aria-controls={open ? id : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
      >
        {children}
      </div>

      {dropContent ? (
        <Popover
          id={id}
          open={popoverOpen}
          anchorEl={popoverEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          {dropContent}
        </Popover>
      ) : menuList?.length > 0 ? (
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className={MenuClassName}
          dropdownWidth={dropdownWidth}
        >
          {menuList.map((menu, idx) => (
            <div className="list_hover">
              <MenuItem
                onClick={() => {
                  menu.onClick();
                  handleClose();
                }}
                disableRipple
                hidden={menu.hidden}
                style={{
                  border: idx !== menuList.length - 1 && "thin solid #f6f6f6",
                }}
                className={`text-capitalize `}
              >
                {menu.label}
              </MenuItem>
            </div>
          ))}
        </StyledMenu>
      ) : (
        ""
      )}
    </>
  );
}
