import { takeLatest, put, call } from "redux-saga/effects";
import categoryListTypes from "./CategoryActionTypes";
import axios from "axios";

import {
  getCategoryByIdResponse,
  getCategoryListResponse,
  postCategoryResponse,
  putCategoryResponse,
} from "./CategoryActions";
import { UnautherizedResponse } from "../../GetUser/GetUserActions";
import { axiosGet, axiosPost, axiosPut } from "../../../Utils/axios";

export function* onGetcategoryList({ payload }) {
  try {
    let response = yield call(() =>
      axiosPost("product_category/index", payload).then((res) => res?.data)
    );

    yield put(getCategoryListResponse(response));
  } catch (error) {
    yield put(getCategoryListResponse(error));
    yield put(UnautherizedResponse(error));
  }
}

export function* onPostcategory({ payload }) {
  try {
    let response = yield call(() =>
      axiosPost("product_category/new", payload).then((res) => res?.data)
    );
    yield put(postCategoryResponse(response));
  } catch (error) {
    yield put(UnautherizedResponse(error));
  }
}

export function* onGetByIdcategory({ payload }) {
  try {
    let response = yield call(() =>
      axiosGet(`product_category/${payload}`, payload).then((res) => res?.data)
    );
    yield put(getCategoryByIdResponse(response));
  } catch (error) {
    yield put(UnautherizedResponse(error));
  }
}

export function* onPutcategory({ payload }) {
  try {
    let response = yield call(() =>
      axiosPut(`product_category/${payload._id}`, payload).then(
        (res) => res?.data
      )
    );
    yield put(putCategoryResponse(response));
  } catch (error) {
    yield put(UnautherizedResponse(error));
  }
}

export function* categoryListWatcherSaga() {
  yield takeLatest(
    categoryListTypes.GET_CATEGORY_LIST_REQUEST,
    onGetcategoryList
  );
  yield takeLatest(categoryListTypes.POST_CATEGORY_REQUEST, onPostcategory);

  yield takeLatest(categoryListTypes.PUT_CATEGORY_REQUEST, onPutcategory);

  yield takeLatest(
    categoryListTypes.GET_CATEGORY_BYID_REQUEST,
    onGetByIdcategory
  );
}
