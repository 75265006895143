import { takeLatest, put, call } from "redux-saga/effects";
import UserTypes from "./GetUserActionTypes";
import { UnautherizedResponse, getUserResponse } from "./GetUserActions";
import { axiosGet } from "../../Utils/axios";

export function* onGetUser() {
  try {
    let response = yield call(() =>
      axiosGet("auth/current_user_detail").then((res) => res?.data)
    );

    yield put(getUserResponse(response));
  } catch (error) {
    yield put(UnautherizedResponse(error));
  }
}

export function* GetUserWatcherSaga() {
  yield takeLatest(UserTypes.GET_USER_REQUEST, onGetUser);
}
