import CountryListTypes from "./CountryActionTypes";

//-------CountryListTypes GET------------------------
export const getCountryListRequest = (payload) => ({
  type: CountryListTypes.GET_COUNTRY_LIST_REQUEST,
  payload: payload,
});

export const getCountryListResponse = (payload) => ({
  type: CountryListTypes.GET_COUNTRY_LIST_RESPONSE,
  payload: payload,
});
