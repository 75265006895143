import StatListTypes from "./StateActionTypes";

const initialState = {
  StatListLoading: false,
  StatListResponse: null,
};

const StateListReducer = (state = initialState, action) => {
  switch (action.type) {
    case StatListTypes.GET_STATE_LIST_REQUEST:
      return {
        ...state,
        StatListLoading: true,
      };

    case StatListTypes.GET_STATE_LIST_RESPONSE:
      return {
        ...state,
        StatListLoading: false,
        StatListResponse: action.payload,
      };

    default:
      return state;
  }
};

export default StateListReducer;
