import React, { useEffect, useState } from "react";
import "./index.css";
import CustomButton from "../../Components/Atoms/CustomButton";
import { EditOutlined, RemoveRedEyeOutlined } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import AddEmployees from "./AddEmployee";
import TablePagination from "../../Components/Molecules/Pagination";
import DoneIcon from "@mui/icons-material/Done";
import {
  getEmployeeListRequest,
  putEmployeeRequest,
  putEmployeeResponseClear,
} from "../../Redux/Employees/EmployeesActions";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import NoRecords from "../../Components/Atoms/NoRecord";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import EmployeeView from "./EmployeeView";
import AvailableStatus from "../../Components/Atoms/AvailableStatus";
import toast from "react-hot-toast";

const Employees = () => {
  const dispatch = useDispatch();

  const {
    EmployeeListPageLoading,
    EmployeePostLoading,
    EmployeePutLoading,
    EmployeeListPageResponse,
    EmployeePutResponse,
  } = useSelector((state) => state.employee);
  const [viewEmployee, setViewEmployee] = useState({
    openView: false,
    employeVieweData: null,
  });

  const { openView, employeVieweData } = viewEmployee;

  const handleOpenView = (data = null) => {
    setViewEmployee({
      openView: true,
      employeVieweData: data,
    });
  };

  const handleCloseView = () => {
    setViewEmployee({
      open: false,
      employeeData: null,
    });
  };

  const [dialogState, setDialogState] = useState({
    open: false,
    employeeData: null,
  });

  const { open, employeeData } = dialogState;

  const handleOpen = (data = null) => {
    setDialogState({
      open: true,
      employeeData: data,
    });
  };

  const handleClose = () => {
    setDialogState({
      open: false,
      employeeData: null,
    });
  };
  const [page, setPage] = useState(1);
  const searchSchema = {
    filters: {
      search: {
        value: "",
      },
    },
    limit: 10,
    page: page,
    sort: "",
  };

  const searchFormik = useFormik({
    initialValues: searchSchema,
    onSubmit: (values) => {
      setPage(1);
      return dispatch(getEmployeeListRequest(values));
    },
  });

  useEffect(() => {
    dispatch(getEmployeeListRequest({ ...searchFormik.values, page }));
  }, [EmployeePostLoading, EmployeePutLoading]);

  const toggleStatus = (id) => {
    const selectedItem = EmployeeListPageResponse?.data?.find(
      (each) => each._id === id
    );

    if (selectedItem) {
      const updatedData = {
        ...selectedItem,
        status: selectedItem.status === true ? false : true,
      };

      dispatch(putEmployeeRequest({ ...updatedData, id }));
    }
  };

  useEffect(() => {
    if (EmployeePutResponse?.success && EmployeePutResponse?.message) {
      toast.success(EmployeePutResponse?.message);
    } else if (EmployeePutResponse?.response?.data) {
      toast.error(EmployeePutResponse?.response?.data?.message);
    }

    return () => {
      dispatch(putEmployeeResponseClear());
    };
  }, [EmployeePutResponse]);

  return (
    <>
      <CustomDialog
        maxWidth="md"
        title={employeeData ? "Update Employee" : "Add Employee"}
        handleClose={() => {
          handleClose();
        }}
        open={open}
      >
        <div>
          <AddEmployees handleClose={handleClose} employeeData={employeeData} />
        </div>
      </CustomDialog>
      <CustomDialog
        maxWidth="md"
        title={"View Employee"}
        handleClose={() => {
          handleCloseView();
        }}
        open={openView}
      >
        <div>
          <EmployeeView
            handleClose={handleCloseView}
            employeeData={employeVieweData}
          />
        </div>
      </CustomDialog>
      <div className="cntr">
        <div className="hdrComp">
          <h6>Employees</h6>
          <div className="rtActnWrpr">
            <CustomButton
              label={<>Add Employee</>}
              onClick={() => handleOpen()}
              size="small"
            />
          </div>
        </div>

        {EmployeeListPageLoading &&
        !EmployeeListPageResponse?.data?.length > 0 ? (
          <div className="d-flex align-items-center justify-content-center">
            <CircularProgress size={25} />
          </div>
        ) : (
          <div className="tblWrpr">
            <TablePagination
              showFilter={true}
              showSort={true}
              setPage={setPage}
              page={page}
              initialValues={searchSchema}
              formik={searchFormik}
              action={(values) => dispatch(getEmployeeListRequest(values))}
              result={EmployeeListPageResponse}
              count={EmployeeListPageResponse?.data?.total_count}
            >
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Employee Id</th>
                      <th>Name</th>
                      <th>Phone Number</th>
                      <th>Email</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {EmployeeListPageResponse?.data?.length > 0 ? (
                      EmployeeListPageResponse?.data?.map((data, index) => {
                        return (
                          <tr key={data._id}>
                            <td>
                              {(page - 1) * searchFormik.values.limit +
                                index +
                                1}
                            </td>
                            <td>{data.employee_id}</td>
                            <td>{data.first_name}</td>

                            <td>{data.phone_number}</td>
                            <td>{data.email}</td>
                            <td>
                              <AvailableStatus
                                status={data?.status ? "Active" : "InActive"}
                                type={data?.status}
                              />
                            </td>

                            <td>
                              <div className="view">
                                <button onClick={() => handleOpenView(data)}>
                                  <VisibilityIcon />
                                </button>
                                <button onClick={() => handleOpen(data)}>
                                  <EditIcon />
                                </button>
                                <button onClick={() => toggleStatus(data._id)}>
                                  {data.status === true ? (
                                    <RemoveCircleOutlineIcon />
                                  ) : (
                                    <DoneIcon />
                                  )}
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <NoRecords />
                    )}
                  </tbody>
                </table>
              </div>
            </TablePagination>
          </div>
        )}
      </div>
    </>
  );
};

export default Employees;
