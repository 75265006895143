import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomDialog from "../../Components/Molecules/CustomDialog";
import AllotForm from "./AllotForm";
import { getSampleListRequest } from "../../Redux/Sample/SampleActions";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import NoRecords from "../../Components/Atoms/NoRecord";
import TablePagination from "../../Components/Molecules/Pagination";
import TableHeader from "../../Components/Atoms/TableHeader";
import SearchInput from "../../Components/Atoms/SearchInput";
import EditIcon from "@mui/icons-material/Edit";

import ExportData, {
  handleDownloadFile,
} from "../../Components/Molecules/ExportData";
import moment from "moment";
import CustomButton from "../../Components/Atoms/CustomButton";
import { CircularProgress, Drawer } from "@mui/material";
import SampleForm from "./SampleForm";
import UnAllotedFilter from "./Filters/UnAllotedFilter";
import toast from "react-hot-toast";
import { getPurposeOfTestingDisplayName } from "../../Utils/getters";

const UnAlloted = ({ tabs, selectedTab, handleTabChange }) => {
  const { SampleListPageResponse, SamplePutLoading, SampleListPageLoading } =
    useSelector((state) => state.sample);
  const { getUserResponse } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState(null);
  const [filter, setFilter] = useState(false);
  const [reset, setReset] = useState(false);

  const openFilter = () => {
    setFilter(true);
  };
  const closeFilter = () => {
    setFilter(false);
  };

  const hanleOpen = (id) => {
    setOpen(true);
    setId(id);
  };
  const handleClose = () => {
    setOpen(false);
    setId(null);
  };
  const [page, setPage] = useState(1);
  // const searchSample = {
  //   filters: {
  //     search: {
  //       value: "",
  //     },
  //     status: {
  //       value: "UN_ALLOTTED",
  //     },
  //   },
  //   limit: 10,
  //   page: page,
  //   sort: "",
  // };
  // const searchFormik = useFormik({
  //   initialValues: searchSample,
  //   onSubmit: (values) => {
  //     return dispatch(getSampleListRequest(values));
  //   },
  // });

  const searchSample = {
    filters: {
      search: {
        value: "",
      },
      status: {
        value: "UN_ALLOTTED",
      },
      product_category_ref: {
        value: [],
        type: "array",
      },
      tested_by_ref: {
        value: [],
        type: "array",
      },
      sample_condition_ref: {
        value: [],
        type: "array",
      },
      rpt_expiry_date: {
        value: [],
        type: "date_range",
      },
      received_date: {
        value: [],
        type: "date_range",
      },

      received_by_ref: {
        value: [],
        type: "date_range",
      },
    },
    limit: 10,
    page: page,
    sort: "",
  };
  const searchFormik = useFormik({
    initialValues: searchSample,

    onSubmit: (values) => {
      if (
        values.filters.rpt_expiry_date.value[0] &&
        !values.filters.rpt_expiry_date.value[1]
      ) {
        toast.error("Expiry to date is required");
        return;
      } else if (
        !values.filters.rpt_expiry_date.value[0] &&
        values.filters.rpt_expiry_date.value[1]
      ) {
        toast.error("Expiry from date is required");
        return;
      } else if (
        values.filters.rpt_expiry_date.value[0] >
        values.filters.rpt_expiry_date.value[1]
      ) {
        toast.error("Expiry to date should not less than from date");
        return;
      } else if (
        values.filters.received_date.value[0] &&
        !values.filters.received_date.value[1]
      ) {
        toast.error("Received to date is required");
        return;
      } else if (
        !values.filters.received_date.value[0] &&
        values.filters.received_date.value[1]
      ) {
        toast.error("Received from date is required");
        return;
      } else if (
        values.filters.received_date.value[0] >
        values.filters.received_date.value[1]
      ) {
        toast.error("Received to date should not less than from date");
        return;
      }
      setPage(1);
      setReset(true);
      return dispatch(getSampleListRequest(payload));
    },
  });
  const payload = {
    filters: {
      search: {
        value: searchFormik.values.filters.search?.value,
      },
      status: {
        value: "UN_ALLOTTED",
      },
      product_category_ref: {
        value:
          searchFormik.values.product_category_ref?.value?.map(
            (el) => el?._id
          ) || [],
        type: "array",
      },

      sample_condition_ref: {
        value:
          searchFormik.values.sample_condition_ref?.value?.map(
            (el) => el?._id
          ) || [],
        type: "array",
      },

      tested_by_ref: {
        value:
          searchFormik.values.tested_by_ref?.value?.map((el) => el?._id) || [],
        type: "array",
      },

      received_by_ref: {
        value:
          searchFormik.values.received_by_ref?.value?.map((el) => el?._id) ||
          [],
        type: "array",
      },
      rpt_expiry_date: {
        value:
          searchFormik.values.filters.rpt_expiry_date?.value[0] &&
          searchFormik.values.filters.rpt_expiry_date?.value[1]
            ? [
                searchFormik.values.filters.rpt_expiry_date?.value[0],
                searchFormik.values.filters.rpt_expiry_date?.value[1],
              ]
            : [],
        type: "date_range",
      },
      received_date: {
        value:
          searchFormik.values.filters.received_date?.value[0] &&
          searchFormik.values.filters.received_date?.value[1]
            ? [
                searchFormik.values.filters.received_date?.value[0],
                searchFormik.values.filters.received_date?.value[1],
              ]
            : [],
        type: "date_range",
      },
    },
    limit: 10,
    page: 1,
    sort: "",
  };

  useEffect(() => {
    dispatch(getSampleListRequest(payload));
  }, [SamplePutLoading, reset]);

  const headers = [
    "received_date",
    "applicant_name",
    "product_category_ref",
    "model_no",
    "no_of_samples_received",
    "standard",
    "allot_srf_id",
    "rpt_testreport_no",
    "rpt_issue_date",
    "tested_by_ref",
    "rpt_expiry_date",
    "status",
    "sub_status",
  ];
  const values = {
    filters: searchFormik.values.filters,
  };
  const handleExportButtonClick = () => {
    const date = moment(new Date()).format("DD_MM_YYYY");
    handleDownloadFile({
      url: "sample/report/get_report",
      filename: `unallotted_${date}.xlsx`,
      payload: { headers, ...values },
      method: "POST",
    });
  };

  const [sample, setSample] = useState(false);
  const [sampleEditData, setSampleEditData] = useState(false);

  const handleSampleEditOpen = (data) => {
    setSample(true);
    setSampleEditData(data);
  };
  const handleSampleEditClose = () => {
    setSample(false);
    setSampleEditData(null);
  };

  const handleFilter = () => {
    searchFormik.resetForm();
    setReset(false);
  };
  return (
    <>
      <Drawer anchor="right" open={filter} onClose={closeFilter}>
        <UnAllotedFilter
          searchFormik={searchFormik}
          handleFilterClose={closeFilter}
          open={filter}
          handleFilter={handleFilter}
        />
      </Drawer>
      <CustomDialog
        maxWidth="md"
        title="Allot For Testing"
        handleClose={() => {
          handleClose();
        }}
        open={open}
      >
        <AllotForm id={id} handleClose={handleClose} />
      </CustomDialog>

      <CustomDialog
        maxWidth="md"
        title={sampleEditData ? "Edit  Sample" : "Add  Sample"}
        handleClose={() => {
          handleSampleEditClose();
        }}
        open={sample}
      >
        <SampleForm
          handleClose={handleSampleEditClose}
          sampleData={sampleEditData}
          sample={sample}
          type="popup_edit"
        />
      </CustomDialog>

      <TableHeader
        tabs={tabs}
        role={getUserResponse?.data?.role}
        selectedTab={selectedTab}
        handleTabChange={handleTabChange}
        searchFormik={searchFormik}
        showTab={true}
      >
        <SearchInput
          value={searchFormik.values.filters.search.value}
          name={"filters.search.value"}
          onChange={searchFormik.handleChange}
          handleSubmit={searchFormik.handleSubmit}
          searchFormik={searchFormik}
          searchSample={searchSample}
        />
        <CustomButton
          onClick={handleExportButtonClick}
          label={"Export"}
          size="small"
        />

        <CustomButton onClick={openFilter} label={"Filter"} size="small" />
      </TableHeader>
      {SampleListPageLoading && !SampleListPageLoading?.data?.length > 0 ? (
        <div className="d-flex align-items-center justify-content-center">
          <CircularProgress size={25} />
        </div>
      ) : (
        <TablePagination
          showFilter={true}
          showSort={true}
          initialValues={searchSample}
          formik={searchFormik}
          setPage={setPage}
          page={page}
          action={(values) => dispatch(getSampleListRequest(values))}
          result={SampleListPageResponse}
          count={SampleListPageResponse?.data?.total_count}
        >
          <div className="tblWrpr">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Rcvd.&nbsp;Date</th>
                    <th>Applicant&nbsp;Name</th>
                    <th>Category</th>
                    <th>Model&nbsp;No.</th>
                    <th>No.&nbsp;Samples&nbsp;Rcvd</th>
                    <th>Condition</th>
                    <th>Rcvd.&nbsp;By</th>
                    <th>Purpose&nbsp;of&nbsp;Testing</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {SampleListPageResponse?.data?.length > 0 ? (
                    SampleListPageResponse?.data?.map((data, index) => {
                      return (
                        <tr key={data._id}>
                          <td>
                            {searchFormik?.values?.limit * (page - 1) +
                              index +
                              1}
                          </td>
                          <td>
                            {moment(data?.received_date).format("DD/MM/YYYY")}
                          </td>
                          <td>{data.applicant_name}</td>
                          <td>{data.product_category_ref?.name}</td>
                          <td>{data.model_no}</td>
                          <td>{data.no_of_samples_received}</td>
                          <td className="td">
                            {data.sample_condition_ref?.name}
                          </td>
                          <td>{data.received_by_ref?.first_name}</td>
                          <td>
                            {getPurposeOfTestingDisplayName(
                              data?.purpose_of_testing
                            )}
                            {/* {data?.purpose_of_testing === "BIS_MODIFICATION"
                              ? "BIS Modification"
                              : data?.purpose_of_testing === "BIS_FULLTESTING"
                              ? "BIS Fulltesting"
                              : data?.purpose_of_testing === "NABL_PERFORMANCE"
                              ? "NABL Perfomance"
                              : data?.purpose_of_testing === "R_AND_D"
                              ? "R&D"
                              : data?.purpose_of_testing === "OTHERS"
                              ? "Others"
                              : ""} */}
                          </td>

                          <td className="">
                            <div className="view">
                              <button
                                onClick={() => handleSampleEditOpen(data)}
                              >
                                <EditIcon />
                              </button>
                              <Link onClick={() => hanleOpen(data?._id)}>
                                Click&nbsp;here&nbsp;to&nbsp;allot&nbsp;for&nbsp;testing
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <NoRecords />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </TablePagination>
      )}
    </>
  );
};

export default UnAlloted;
