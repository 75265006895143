import React, { useEffect } from "react";
import "./index.css";
import { NavLink } from "react-router-dom";
import MuiDrawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import ExpandLess from "@mui/icons-material/ExpandLess";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import ExpandMore from "@mui/icons-material/ExpandMore";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";

const drawerWidth = 260;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideNav = ({ handleDrawerOpen, handleDrawerClose, nav }) => {
  const { getUserResponse } = useSelector((state) => state.user);

  const isMatches = useMediaQuery("(max-width:991px)");

  const sideNavaData = [
    {
      primary: "Dashboard",
      link: "/",
      img: <SpaceDashboardIcon />,
    },
    {
      primary: "Samples",
      link: "/samples",
      img: <InsertDriveFileIcon />,
    },
    {
      primary: "Employees",
      link: "/employees",
      img: <SupervisedUserCircleIcon />,
      hidden: getUserResponse?.data?.role === "STANDARD",
    },
    {
      primary: "Settings",
      link: "/settingscategory",
      img: <SettingsIcon />,
      hidden: getUserResponse?.data?.role === "STANDARD",
    },
  ];

  const filteredSideNavData = sideNavaData.filter((item) => !item.hidden);

  const ResponsiveDrawer = ({ children }) => {
    return isMatches ? (
      <SwipeableDrawer
        anchor="left"
        open={nav}
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
      >
        <div className="sideNavComp">
          <IconButton
            className="toglNvBtn"
            onClick={nav ? handleDrawerClose : handleDrawerOpen}
          >
            {nav ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          {children}
        </div>
      </SwipeableDrawer>
    ) : (
      <div className="sideNavComp">
        <IconButton
          className="toglNvBtn"
          onClick={nav ? handleDrawerClose : handleDrawerOpen}
        >
          {nav ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
        <Drawer variant="permanent" open={nav}>
          {children}
        </Drawer>
      </div>
    );
  };

  return (
    <ResponsiveDrawer>
      <div className="sideNavHdr">
        {!isMatches && !nav ? (
          <>
            <img
              className={`mnLogo ${!isMatches && !nav ? "lgoSmll" : ""}`}
              src="/Assets/Images/reactico.png"
            />
          </>
        ) : (
          <img
            className={`mnLogo ${!isMatches && !nav ? "lgoSmll" : ""}`}
            src="/Assets/Images/react-logo.png"
          />
        )}
      </div>

      <List className="sideNavLinks">
        <>
          {filteredSideNavData.map((el, idx) => (
            <React.Fragment key={idx}>
              <CustomNav
                {...el}
                open={nav}
                setOpen={handleDrawerOpen}
                handleDrawerClose={handleDrawerClose}
              />
            </React.Fragment>
          ))}
        </>
      </List>
    </ResponsiveDrawer>
  );
};

export default SideNav;

const CustomNav = ({
  open,
  primary,
  isDropDown = false,
  link,
  pathnames = [],
  img,
  setOpen,
  navlinks,
  hidden,
  handleDrawerClose,
}) => {
  const [tab, setTab] = React.useState(false);
  const isMatches = useMediaQuery("(max-width:991px)");
  const handleMenuClick = () => {
    setTab(!tab);
    setOpen(true);
  };

  const setActiveState = (path) => {
    return (
      window.location.pathname
        .toLowerCase()
        .includes(`${path.toLowerCase()}`) && setTab(true)
    );
  };

  useEffect(() => {
    if (pathnames.length > 0) {
      pathnames.some(setActiveState);
    }
  }, [window.location.pathname]);

  return (
    <>
      {isDropDown ? (
        <>
          <ListItem hidden={hidden} disablePadding sx={{ display: "block" }}>
            <NavLink
              className={tab ? "active-style" : ""}
              onClick={() => {
                handleMenuClick();
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: isMatches
                    ? "initial"
                    : open
                    ? "initial"
                    : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isMatches ? 2 : open ? 2 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {img}
                </ListItemIcon>
                <ListItemText
                  primary={isMatches ? `${primary}` : open ? `${primary}` : ""}
                />
                {isMatches ? (
                  tab ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : open ? (
                  tab ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )
                ) : (
                  ""
                )}
              </ListItemButton>
            </NavLink>
          </ListItem>

          {open && isDropDown && (
            <Collapse in={tab && !hidden} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {navlinks.map((nav, idx) => (
                  <NavLink
                    hidden={nav.hidden}
                    key={idx}
                    className={({ isActive }) =>
                      isActive ? "active-style" : "none"
                    }
                    to={nav.link}
                    onClick={() => isMatches && handleDrawerClose()}
                  >
                    <ListItem button>
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: 5,
                          justifyContent: "center",
                        }}
                      ></ListItemIcon>
                      <ListItemText primary={nav.primary} />
                    </ListItem>
                  </NavLink>
                ))}
              </List>
            </Collapse>
          )}
        </>
      ) : (
        <ListItem disablePadding sx={{ display: "block" }}>
          <NavLink
            className={({ isActive }) => (isActive ? "active-style" : "none")}
            to={link}
            // active className="active"
            onClick={() => isMatches && handleDrawerClose()}
          >
            <ListItemButton
              disableRipple
              sx={{
                minHeight: 48,
                justifyContent: isMatches
                  ? "initial"
                  : open
                  ? "initial"
                  : "center",
                px: 2.5,
              }}
            >
              {img && (
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isMatches ? 2 : open ? 2 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {img}
                </ListItemIcon>
              )}
              <ListItemText
                primary={`${primary}`}
                sx={{ opacity: isMatches ? 1 : open ? 1 : 0 }}
              />
            </ListItemButton>
          </NavLink>
        </ListItem>
      )}
    </>
  );
};
