import PropTypes from "prop-types";
import { useRef, useEffect } from "react";
import "./index.css";

const truncateText = (text, maxLength) => {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
};

const CustomSelect = ({
  value,
  onChange,
  name,
  setValue = "value",
  setOption = "name",
  placeholder,
  errorMsg,
  onBlur,
  data_testid,
  data,
  multiple,
  disabled,
  scrollIntoView = () => {},
  maxOptionLength = 35,
}) => {
  const errorRef = useRef(null);

  useEffect(() => {
    if (errorRef?.current) {
      scrollIntoView(errorRef?.current);
    }
  });

  return (
    <div className="customInputWrpr">
      <select
        disabled={disabled}
        data-testid={data_testid}
        multiple={multiple ? true : false}
        value={value || placeholder}
        className={errorMsg ? "dropdown-input bounce" : "dropdown-input"}
        onChange={onChange}
        name={name}
        onBlur={onBlur}
      >
        <option value={placeholder} disabled>
          {placeholder}
        </option>
        {data?.map((each, index) => (
          <option key={index} value={each[setValue]}>
            {Array.isArray(setOption)
              ? `${truncateText(
                  each[setOption[0]],
                  maxOptionLength
                )} - ${truncateText(each[setOption[1]], maxOptionLength)}`
              : truncateText(each[setOption], maxOptionLength)}
          </option>
        ))}
      </select>
      {errorMsg && (
        <p ref={errorRef} className="errMsg">
          {errorMsg}
        </p>
      )}
    </div>
  );
};

export default CustomSelect;
