import SampleListTypes from "./SampleActionTypes";

const initialState = {
  SampleListPageLoading: false,
  SampleListPageResponse: null,
  SamplePostLoading: false,
  SamplePostResponse: null,
  SamplePutLoading: false,
  SamplePutResponse: null,
  SampleStatusLoading: false,
  SampleStatusResponse: null,
};

const SampleListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SampleListTypes.GET_SAMPLE_LIST_REQUEST:
      return {
        ...state,
        SampleListPageLoading: true,
      };

    case SampleListTypes.GET_SAMPLE_LIST_RESPONSE:
      return {
        ...state,
        SampleListPageLoading: false,
        SampleListPageResponse: action.payload.data,
      };
    case SampleListTypes.GET_SAMPLE_LIST_RESET:
      return {
        ...state,

        SampleListPageResponse: null,
      };

    case SampleListTypes.GET_SAMPLE_STATUS_REQUEST:
      return {
        ...state,
        SampleStatusLoading: true,
      };

    case SampleListTypes.GET_SAMPLE_STATUS_RESPONSE:
      return {
        ...state,
        SampleStatusLoading: false,
        SampleStatusResponse: action.payload,
      };

    case SampleListTypes.POST_SAMPLE_REQUEST:
      return {
        ...state,
        SamplePostLoading: true,
      };

    case SampleListTypes.POST_SAMPLE_RESPONSE:
      return {
        ...state,
        SamplePostLoading: false,
        SamplePostResponse: action.payload,
      };

    case SampleListTypes.POST_SAMPLE_RESPONSE_CLEAR:
      return {
        ...state,
        SamplePostResponse: null,
      };

    case SampleListTypes.PUT_SAMPLE_REQUEST:
      return {
        ...state,
        SamplePutLoading: true,
      };

    case SampleListTypes.PUT_SAMPLE_RESPONSE:
      return {
        ...state,
        SamplePutLoading: false,
        SamplePutResponse: action.payload,
      };

    case SampleListTypes.PUT_SAMPLE_RESPONSE_CLEAR:
      return {
        ...state,
        SamplePutResponse: null,
      };

    default:
      return state;
  }
};

export default SampleListReducer;
