import CategoryListTypes from "./CategoryActionTypes";

const initialState = {
  CategoryListPageLoading: false,
  CategoryListPageResponse: null,
  CategoryPostLoading: false,
  CategoryPostResponse: null,
  CategoryPutLoading: false,
  CategoryPutResponse: null,
  CategoryByIdPageLoading: false,
  CategoryByIdPageResponse: null,
};

const CategoryListReducer = (state = initialState, action) => {
  switch (action.type) {
    case CategoryListTypes.GET_CATEGORY_LIST_REQUEST:
      return {
        ...state,
        CategoryListPageLoading: true,
      };

    case CategoryListTypes.GET_CATEGORY_LIST_RESPONSE:
      return {
        ...state,
        CategoryListPageLoading: false,
        CategoryListPageResponse: action.payload.data,
      };

    case CategoryListTypes.POST_CATEGORY_REQUEST:
      return {
        ...state,
        CategoryPostLoading: true,
      };

    case CategoryListTypes.POST_CATEGORY_RESPONSE:
      return {
        ...state,
        CategoryPostLoading: false,
        CategoryPostResponse: action.payload,
      };

    case CategoryListTypes.POST_CATEGORY_RESPONSE_CLEAR:
      return {
        ...state,
        CategoryPostResponse: null,
      };

    case CategoryListTypes.GET_CATEGORY_BYID_REQUEST:
      return {
        ...state,
        CategoryByIdPageLoading: true,
      };

    case CategoryListTypes.GET_CATEGORY_BYID_RESPONSE:
      return {
        ...state,
        CategoryByIdPageLoading: false,
        CategoryByIdPageResponse: action.payload,
      };

    case CategoryListTypes.GET_CATEGORY_BYID_RESCLEAR:
      return {
        ...state,
        CategoryByIdPageResponse: null,
      };

    case CategoryListTypes.PUT_CATEGORY_REQUEST:
      return {
        ...state,
        CategoryPutLoading: true,
      };

    case CategoryListTypes.PUT_CATEGORY_RESPONSE:
      return {
        ...state,
        CategoryPutLoading: false,
        CategoryPutResponse: action.payload,
      };

    case CategoryListTypes.PUT_CATEGORY_RESPONSE_CLEAR:
      return {
        ...state,
        CategoryPutResponse: null,
      };

    default:
      return state;
  }
};

export default CategoryListReducer;
