import ForgotAndResetPasswordTypes from "./ForgotAndResetPasswordActionTypes";

const initialState = {
  PostForgotPasswordLoading: false,
  PostForgotPasswordResponse: null,
  PostResetPasswordLoading: false,
  PostResetPasswordResponse: null,
};

const ForgotAndResetPasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case ForgotAndResetPasswordTypes.POST_FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        PostForgotPasswordLoading: true,
      };

    case ForgotAndResetPasswordTypes.POST_FORGOT_PASSWORD_RESPONSE:
      return {
        ...state,
        PostForgotPasswordLoading: false,
        PostForgotPasswordResponse: action.payload,
      };

    case ForgotAndResetPasswordTypes.POST_FORGOT_PASSWORD_RESPONSE_CLEAR:
      return {
        ...state,
        PostForgotPasswordResponse: null,
      };

    case ForgotAndResetPasswordTypes.POST_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        PostResetPasswordLoading: true,
      };

    case ForgotAndResetPasswordTypes.POST_RESET_PASSWORD_RESPONSE:
      return {
        ...state,
        PostResetPasswordLoading: false,
        PostResetPasswordResponse: action.payload,
      };

    case ForgotAndResetPasswordTypes.POST_RESET_PASSWORD_RESPONSE_CLEAR:
      return {
        ...state,
        PostResetPasswordResponse: null,
      };

    default:
      return state;
  }
};

export default ForgotAndResetPasswordReducer;
