import CategoryListTypes from "./CategoryActionTypes";

//------conditionlist GET------------------------
export const getCategoryListRequest = (payload) => ({
  type: CategoryListTypes.GET_CATEGORY_LIST_REQUEST,
  payload: payload,
});

export const getCategoryListResponse = (payload) => ({
  type: CategoryListTypes.GET_CATEGORY_LIST_RESPONSE,
  payload: payload,
});

//--------condition POST--------------------------
export const postCategoryRequest = (payload) => ({
  type: CategoryListTypes.POST_CATEGORY_REQUEST,
  payload: payload,
});

export const postCategoryResponse = (payload) => ({
  type: CategoryListTypes.POST_CATEGORY_RESPONSE,
  payload: payload,
});

export const postCategoryResponseClear = () => ({
  type: CategoryListTypes.POST_CATEGORY_RESPONSE_CLEAR,
});

//--------condition PUT--------------------------------
export const putCategoryRequest = (payload) => ({
  type: CategoryListTypes.PUT_CATEGORY_REQUEST,
  payload: payload,
});

export const putCategoryResponse = (payload) => ({
  type: CategoryListTypes.PUT_CATEGORY_RESPONSE,
  payload: payload,
});

export const putCategoryResponseClear = () => ({
  type: CategoryListTypes.PUT_CATEGORY_RESPONSE_CLEAR,
});

export const getCategoryByIdRequest = (payload) => ({
  type: CategoryListTypes.GET_CATEGORY_BYID_REQUEST,
  payload: payload,
});

export const getCategoryByIdResponse = (payload) => ({
  type: CategoryListTypes.GET_CATEGORY_BYID_RESPONSE,
  payload: payload,
});
export const getCategoryByIdResponseClear = () => ({
  type: CategoryListTypes.GET_CATEGORY_BYID_RESCLEAR,
});
