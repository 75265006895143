import { takeLatest, put, call } from "redux-saga/effects";
import ChangePasswordTypes from "./ChangePasswordActionType";
import { changepasswordResponse } from "./ChangePasswordAction";
import { axiosPost } from "../../Utils/axios";

export function* onChangePassword({ payload, id }) {
  try {
    let response = yield call(() =>
      axiosPost("auth/change_password", payload).then((res) => res?.data)
    );

    yield put(changepasswordResponse(response));
  } catch (error) {
    yield put(changepasswordResponse(error));
  }
}

export function* changepasswordWatcherSaga() {
  yield takeLatest(
    ChangePasswordTypes.CHANGE_PASSWORD_REQUEST,
    onChangePassword
  );
}
