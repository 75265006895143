import React, { useEffect } from "react";
import CustomInput from "../../../Components/Atoms/CustomInput";
import CustomButton from "../../../Components/Atoms/CustomButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  postSampleRequest,
  putSampleRequest,
  putSampleResponseClear,
} from "../../../Redux/Sample/SampleActions";
import toast from "react-hot-toast";
import CustomSelect from "../../../Components/Atoms/CustomSelect";
import { getEmployeeListRequest } from "../../../Redux/Employees/EmployeesActions";
import { expression } from "../../../Constant";

const AllotForm = ({ id, handleClose, sampleData }) => {
  const dispatch = useDispatch();
  const { EmployeeListPageResponse } = useSelector((state) => state.employee);
  const { SamplePutResponse, SamplePutLoading } = useSelector(
    (state) => state.sample
  );
  const validationArray = Yup.object({
    allot_testreq_no: Yup.string().required("Required"),
    allot_encoded_code: Yup.string()
      .required("Required")
      .min(3, "Minimum of 3 characters")
      .max(50, "Maximum of 50 characters")
      .matches(expression.space, "Encode  to should not contain spaces"),
    allot_barcode_no: Yup.string()
      .required("Required")
      .min(3, "Minimum of 3 characters")
      .max(50, "Maximum of 50 characters")
      .matches(expression.space, "Barcode number to should not contain spaces"),
    allot_srf_id: Yup.string()
      .required("Required")
      .min(3, "Minimum of 3 characters")
      .max(50, "Maximum of 50 characters")
      .matches(expression.space, "SRF Id  to should not contain spaces"),
    assigned_to_ref: Yup.string().required("Required"),
    end_date: Yup.string().required("Required"),
    allot_manufacturer_name: Yup.string()
      .required("Required")
      .min(3, "Minimum of 3 characters")
      .max(50, "Maximum of 50 characters")
      .matches(
        expression.space,
        "Manufacurer name  to should not contain spaces"
      ),
  });

  const formik = useFormik({
    initialValues: {
      allot_srf_id: "",
      allot_testreq_no: "",
      allot_encoded_code: "",
      allot_barcode_no: "",
      allot_manufacturer_name: "",
      allot_manufacturer_address: "",
      assigned_to_ref: "",
      end_date: "",
      status: "ALLOTTED",
      sub_status: "IN_TESTING",
    },
    validationSchema: validationArray,
    onSubmit: (values) => {
      const payload = { ...values, id };

      if (sampleData) {
        delete payload?.status;
        delete payload?.sub_status;
      }
      dispatch(putSampleRequest(payload));
    },
  });
  useEffect(() => {
    if (SamplePutResponse?.success && SamplePutResponse?.message) {
      handleClose();
      formik.resetForm();
    }
    return () => {
      dispatch(putSampleResponseClear());
    };
  }, [SamplePutResponse]);
  const searchSchema = {
    filters: {
      search: {
        value: "",
      },
      status: {
        value: true,
      },
    },
    sort: "first_name:asc",
  };

  useEffect(() => {
    dispatch(getEmployeeListRequest(searchSchema));
  }, [SamplePutLoading]);

  useEffect(() => {
    if (sampleData) {
      let formatted_end_date = null;
      if (sampleData?.end_date) {
        const end_date = new Date(sampleData?.end_date);
        formatted_end_date = end_date.toISOString().split("T")[0];
      }
      formik.setFieldValue("end_date", formatted_end_date);
      formik.setFieldValue("allot_srf_id", sampleData?.allot_srf_id);
      formik.setFieldValue("allot_testreq_no", sampleData?.allot_testreq_no);
      formik.setFieldValue(
        "allot_encoded_code",
        sampleData?.allot_encoded_code
      );
      formik.setFieldValue("allot_barcode_no", sampleData?.allot_barcode_no);
      formik.setFieldValue(
        "allot_manufacturer_name",
        sampleData?.allot_manufacturer_name
      );
      formik.setFieldValue(
        "allot_manufacturer_address",
        sampleData?.allot_manufacturer_address
      );
      formik.setFieldValue("assigned_to_ref", sampleData?.assigned_to_ref?._id);
    }
  }, [sampleData]);
  return (
    <>
      <div className="">
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex">
                <p className="mb-1">SRF Id</p>
                <p className="erroStar">*</p>
              </div>
              <CustomInput
                type="text"
                name="allot_srf_id"
                value={formik.values.allot_srf_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                placeholder="Enter SRF id"
              />
            </div>
            <div className="col-md-6">
              <div className="d-flex">
                <p className="mb-1">Test Request No</p>
                <p className="erroStar">*</p>
              </div>
              <CustomInput
                type="text"
                name="allot_testreq_no"
                value={formik.values.allot_testreq_no}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                placeholder="Enter request no"
              />
            </div>{" "}
            <div className="col-md-6">
              <div className="d-flex">
                <p className="mb-1">Encoded Code</p>
                <p className="erroStar">*</p>
              </div>
              <CustomInput
                type="text"
                name="allot_encoded_code"
                value={formik.values.allot_encoded_code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                placeholder="Enter encoded code"
              />
            </div>
            <div className="col-md-6">
              <div className="d-flex">
                <p className="mb-1">Barcode No.</p>
                <p className="erroStar">*</p>
              </div>
              <CustomInput
                type="text"
                name="allot_barcode_no"
                value={formik.values.allot_barcode_no}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                placeholder="Enter encoded code"
              />
            </div>
            <div className="col-md-6">
              <div className="d-flex">
                <p className="mb-1">Manufacturer Name</p>
                <p className="erroStar">*</p>
              </div>
              <CustomInput
                type="text"
                name="allot_manufacturer_name"
                value={formik.values.allot_manufacturer_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                placeholder="Enter manufacturer name"
              />
            </div>{" "}
            <div className="col-md-6">
              <div className="d-flex">
                <p className="mb-1">Assigned To</p>
                <p className="erroStar">*</p>
              </div>
              <CustomSelect
                type="text"
                name="assigned_to_ref"
                value={formik.values.assigned_to_ref}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                placeholder="SELECT EMPLOYEE"
                setValue="_id"
                setOption={"first_name"}
                data={EmployeeListPageResponse?.data}
                errorMsg={
                  formik.touched.assigned_to_ref &&
                  formik.errors?.assigned_to_ref
                }
              />
            </div>
            <div className="col-md-6">
              <div className="d-flex">
                <p className="mb-1">End Date</p>
                <p className="erroStar">*</p>
              </div>
              <CustomInput
                type="date"
                name="end_date"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.end_date}
                formik={formik}
              />
            </div>
            <div className="col-md-12">
              <p className="mb-1">Manufacturer Address (Optional)</p>
              <CustomInput
                placeholder="Enter manufacturer address"
                type="text"
                value={formik.values.allot_manufacturer_address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
                name="allot_manufacturer_address"
                multiline
                row={5}
              />
            </div>{" "}
            <div className="btnActnBar">
              <CustomButton
                label={"Cancel"}
                btnType="secondary"
                onClick={() => handleClose()}
              />
              <CustomButton
                label={sampleData ? "Update" : "Submit"}
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default AllotForm;
