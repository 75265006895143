import React, { useEffect } from "react";
import CustomInput from "../../Components/Atoms/CustomInput";
import CustomButton from "../../Components/Atoms/CustomButton";
import * as Yup from "yup";
import {
  putSampleRequest,
  putSampleResponseClear,
} from "../../Redux/Sample/SampleActions";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { expression } from "../../Constant";

const DisposedForm = ({ id, handleClose }) => {
  const dispatch = useDispatch();
  const { SamplePutResponse, SamplePutLoading } = useSelector(
    (state) => state.sample
  );
  const validationArray = Yup.object({
    returned_to: Yup.string()
      .required("Required")
      .min(3, "Minimum of 3 characters")
      .max(30, "Maximum of 30 characters")

      .matches(expression.min_one_char, "Retured to should have a character")
      .matches(expression.space, "Retured to should not contain spaces"),
    returned_on: Yup.string().required("Required"),

    delivery_challan_no: Yup.string()
      .required("Required")
      .min(3, "Minimum of 3 characters")
      .max(30, "Maximum of 30 characters")
      .matches(
        expression.min_one_char,
        "Deliver challan should have a character"
      )
      .matches(expression.space, "Deliver challan should not contain spaces"),
  });

  const formik = useFormik({
    initialValues: {
      returned_to: "",
      returned_on: "",
      delivery_challan_no: "",
      sub_status: "DISPOSED",
    },
    validationSchema: validationArray,
    onSubmit: (values) => {
      dispatch(putSampleRequest({ ...values, id }));
    },
  });
  useEffect(() => {
    if (SamplePutResponse?.success && SamplePutResponse?.message) {
      formik.resetForm();
      handleClose();
    }
    return () => {
      dispatch(putSampleResponseClear());
    };
  }, [SamplePutResponse]);
  return (
    <>
      <div className="">
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex">
                <p className="mb-1">Returned To</p>
                <p className="erroStar">*</p>
              </div>
              <CustomInput
                placeholder="Enter returned to"
                type="text"
                name="returned_to"
                value={formik.values.returned_to}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
              />
            </div>
            <div className="col-md-12">
              <div className="d-flex">
                <p className="mb-1">Returned On</p>
                <p className="erroStar">*</p>
              </div>
              <CustomInput
                type="date"
                placeholder="Enter request no"
                name="returned_on"
                value={formik.values.returned_on}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
              />
            </div>{" "}
            <div className="col-md-12">
              <div className="d-flex">
                <p className="mb-1">Delivery Challan No.</p>
                <p className="erroStar">*</p>
              </div>
              <CustomInput
                placeholder="Enter delivery challan no."
                type="text"
                name="delivery_challan_no"
                value={formik.values.delivery_challan_no}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
              />
            </div>{" "}
            <div className="btnActnBar">
              <CustomButton
                label={"Cancel"}
                btnType="secondary"
                onClick={() => handleClose()}
              />
              <CustomButton label={"Submit"} type="submit" />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default DisposedForm;
