import React, { useEffect } from "react";
import CustomInput from "../../Components/Atoms/CustomInput";
import CustomButton from "../../Components/Atoms/CustomButton";
import * as Yup from "yup";
import {
  putSampleRequest,
  putSampleResponseClear,
} from "../../Redux/Sample/SampleActions";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { expression } from "../../Constant";

const ReportIssuedForm = ({ id, handleClose, sampleData }) => {
  const dispatch = useDispatch();
  const { SamplePutResponse, SamplePutLoading } = useSelector(
    (state) => state.sample
  );
  const validationArray = Yup.object({
    rpt_issue_date: Yup.string().required("Required"),
    rpt_testreport_no: Yup.string()
      .required("Required")
      .trim()
      .min(3, "Minimum of 3 characters")
      .max(30, "Maximum of 30 characters"),

    rpt_ulr_no: Yup.string()
      .required("Required")
      .trim()
      .min(3, "Minimum of 3 characters")
      .max(30, "Maximum of 30 characters"),
  });

  const formik = useFormik({
    initialValues: {
      rpt_testreport_no: "",
      rpt_ulr_no: "",
      rpt_issue_date: "",
      status: "REPORTS_ISSUED",
    },
    validationSchema: validationArray,
    onSubmit: (values) => {
      const payload = { ...values, id };

      if (sampleData) {
        delete payload.status;
      }
      dispatch(putSampleRequest(payload));
    },
  });

  useEffect(() => {
    if (sampleData) {
      let formattedReceivedDate = null;
      if (sampleData?.rpt_issue_date) {
        const receivedDate = new Date(sampleData.rpt_issue_date);
        formattedReceivedDate = receivedDate.toISOString().split("T")[0];
      }

      formik.setFieldValue(
        "rpt_testreport_no",
        sampleData?.rpt_testreport_no || ""
      );
      formik.setFieldValue("rpt_ulr_no", sampleData?.rpt_ulr_no || "");
      formik.setFieldValue("rpt_issue_date", formattedReceivedDate);
    }
  }, [sampleData]);

  useEffect(() => {
    if (SamplePutResponse?.success && SamplePutResponse?.message) {
      formik.resetForm();
      handleClose();
    }

    return () => {
      dispatch(putSampleResponseClear());
    };
  }, [SamplePutResponse]);
  return (
    <>
      <div className="">
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex">
                <p className="mb-1">Test Rpt No.</p>
                <p className="erroStar">*</p>
              </div>
              <CustomInput
                placeholder="Enter test report no."
                type="text"
                name="rpt_testreport_no"
                value={formik.values.rpt_testreport_no}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
              />
            </div>
            <div className="col-md-12">
              <div className="d-flex">
                <p className="mb-1">ULR No.</p>
                <p className="erroStar">*</p>
              </div>
              <CustomInput
                type="text"
                placeholder="Enter ULR no."
                name="rpt_ulr_no"
                value={formik.values.rpt_ulr_no}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
              />
            </div>{" "}
            <div className="col-md-12">
              <div className="d-flex">
                <p className="mb-1">Issue Date</p>
                <p className="erroStar">*</p>
              </div>
              <CustomInput
                type="date"
                name="rpt_issue_date"
                value={formik.values.rpt_issue_date}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
              />
            </div>{" "}
            <div className="btnActnBar">
              <CustomButton
                label={"Cancel"}
                btnType="secondary"
                onClick={() => handleClose()}
              />
              <CustomButton
                label={sampleData ? "Update" : "Submit"}
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default ReportIssuedForm;
