import React, { useEffect, useState } from "react";
import "./index.css";
import AuthLayout from "../../Components/Layout/AuthLayout";
import CustomInput from "../../Components/Atoms/CustomInput";
import CustomButton from "../../Components/Atoms/CustomButton";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import CryptoJS from "crypto-js";
import { useDispatch, useSelector } from "react-redux";
import {
  postLoginRequest,
  postLoginResponseClear,
} from "../../Redux/LoginPage/LoginActions";
import { getUserRequest } from "../../Redux/GetUser/GetUserActions";
import toast from "react-hot-toast";
import { IconButton } from "@mui/material";
import CustomCheckBox from "../../Components/Atoms/CustomCheckbox";
import Cookies from "js-cookie";

const Login = () => {
  const navigate = useNavigate();
  const secret_key = process.env.REACT_APP_SECRET_KEY;
  const { getUserResponse } = useSelector((state) => state.user);
  const { LoginPageResponse, LoginPageLoading } = useSelector(
    (state) => state.loginReducer
  );

  const [passVisible, setPassVisible] = useState(false);
  const dispatch = useDispatch();
  const validationArray = Yup.object().shape({
    employee_id: Yup.string().required("Required"),
    password: Yup.string().min(2, "Too Short!").required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      employee_id: "",
      password: "",
      rememberMe: false,
    },
    validationSchema: validationArray,
    onSubmit: async (values) => {
      if (values.rememberMe && values.employee_id && values.password) {
        // Encrypt and save data to cookies
        const encryptedEmail = CryptoJS.AES.encrypt(
          values.employee_id,
          secret_key
        ).toString();
        const encryptedPassword = CryptoJS.AES.encrypt(
          values.password,
          secret_key
        ).toString();
        Cookies.set("employee_id", encryptedEmail);
        Cookies.set("password", encryptedPassword);
        Cookies.set("rememberMe", true); // Always set to true when checked
      } else {
        // Clear cookies if rememberMe is not checked
        Cookies.remove("password");
        Cookies.remove("employee_id");
        Cookies.remove("rememberMe");
      }
      dispatch(postLoginRequest(values));
    },
  });

  useEffect(() => {
    const rememberMe = Cookies.get("rememberMe") === "true"; // Parse to boolean
    if (rememberMe) {
      const cookieEmail = Cookies.get("employee_id");
      const cookiePassword = Cookies.get("password");
      const decryptedEmail = CryptoJS.AES.decrypt(
        cookieEmail,
        secret_key
      ).toString(CryptoJS.enc.Utf8);
      const decryptedPassword = CryptoJS.AES.decrypt(
        cookiePassword,
        secret_key
      ).toString(CryptoJS.enc.Utf8);

      formik.setValues({
        ...formik.values,
        employee_id: decryptedEmail,
        password: decryptedPassword,
        rememberMe: rememberMe,
      });
    }
  }, []);

  useEffect(() => {
    if (!LoginPageResponse) return;
    if (LoginPageResponse) {
      if (LoginPageResponse?.message && LoginPageResponse?.success) {
        toast.success(LoginPageResponse?.message);
        dispatch(getUserRequest());
      } else {
        toast.error(LoginPageResponse.response?.data?.message);
      }
    }
    return () => {
      dispatch(postLoginResponseClear());
    };
  }, [LoginPageResponse]);

  useEffect(() => {
    if (getUserResponse) {
      navigate("/");
    }
  }, [getUserResponse]);
  return (
    <AuthLayout showButton={true}>
      <div className="lgnFormContainer">
        <form onSubmit={formik.handleSubmit}>
          <div className="frmHdr">
            <p>Login</p>
            <p>Welcome! </p>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex">
                <p className="mb-1">Employee Id</p>
                <p className="erroStar">*</p>
              </div>
              <CustomInput
                type="text"
                name="employee_id"
                placeholder="Enter employee id"
                value={formik.values.employee_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                formik={formik}
              />
            </div>

            <div className="col-md-12">
              <div className="d-flex">
                <p className="mb-1">Password</p>
                <p className="erroStar">*</p>
              </div>
              <CustomInput
                name="password"
                placeholder="Enter assword"
                value={formik.values.password}
                onChange={formik.handleChange}
                type={passVisible ? "text" : "password"}
                onBlur={formik.handleBlur}
                formik={formik}
                endAdornment={
                  <IconButton
                    className="material-symbols-rounded"
                    onClick={() => setPassVisible(!passVisible)}
                  >
                    {passVisible ? "visibility_off" : "visibility"}
                  </IconButton>
                }
              />
            </div>
            <div className="checkbox_container">
              <CustomCheckBox
                name="rememberMe"
                value={formik.values.rememberMe}
                onChange={formik.handleChange}
                checked={formik.values.rememberMe}
                label="Remember me"
              />
            </div>
            <div className="lgin_button mt-3">
              <CustomButton
                label={LoginPageLoading ? "Loading..." : "Login"}
                type="submit"
              />
              <div className="btntext_log">
                <p>Forgot Your Password?</p>
                <Link to={"/forgotpassword"}>Click here to reset</Link>
              </div>
            </div>
          </div>
        </form>
      </div>
    </AuthLayout>
  );
};

export default Login;
