import React from "react";
import Tab from "../../Molecules/Tabs";
import SearchInput from "../SearchInput";
import ExportData from "../../Molecules/ExportData";
import { ROLE } from "../../../Constant";

function TableHeader({
  tabs,
  role,
  selectedTab,
  handleTabChange,
  searchFormik,
  children,
  showTab,
}) {
  const filterd_tabs = tabs.filter((e) => e.role.includes(role));

  return (
    <div className="hdrComp">
      <div>
        {showTab && (
          <Tab
            tabs={filterd_tabs}
            selectedTab={selectedTab}
            handleTabChange={handleTabChange}
          />
        )}
      </div>
      <div className="rtActnWrpr">{children}</div>
    </div>
  );
}

export default TableHeader;
