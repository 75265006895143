import StateListTypes from "./StateActionTypes";

//-------StateListTypes GET------------------------
export const getStateListRequest = (payload) => ({
  type: StateListTypes.GET_STATE_LIST_REQUEST,
  payload: payload,
});

export const getStateListResponse = (payload) => ({
  type: StateListTypes.GET_STATE_LIST_RESPONSE,
  payload: payload,
});
