import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "@redux-saga/core";
import rootReducer from "./RootReducer";
import RootSaga from "./RootSaga";
import { getUserRequest } from "./GetUser/GetUserActions";
import { setAuthToken } from "../Utils/axios";

const sagaMiddleWare = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleWare));
const token = localStorage.getItem("token");

sagaMiddleWare.run(RootSaga);

if (token) {
  setAuthToken(token);
  store.dispatch(getUserRequest());
}

export default store;
